import React, { useState, useRef, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import Logo from "../../assets/logo.svg";
import QuizModule from "./QuizModule";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import { ToastContainer } from "react-toastify";
import { Buffer } from "buffer";
import { useGetContentsQuery } from "../../features/contentsApiSlice";
import { Previous } from "../../Constants";
import { useTranslation } from "react-i18next";
import { useMetaApiMutation } from "../../features/metaApiSlice";
import { Icon } from "../../Components/elements/icon";

const Quiz = () => {
  const idImage = Buffer.from("58").toString("base64");
  const [headerImage, setHeaderImage] = useState("");

  const previousRef = useRef();
  const [showProgressBar, setShowProgressBar] = useState(true);

  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState("en");
  const [skip, setSkip] = useState(false);
  const { t } = useTranslation();

  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    //console.log(lang)
  }, [lang, selectedLang]);

  const {
    data: fetchHeaderImage,
    isSuccess: isSuccessImage,
    refetch: refetchImage,
  } = useGetContentsQuery({ id: idImage, lang: "en", skip: skip });

  useEffect(() => {
    if (isSuccessImage) {
      //console.log(fetchHeaderImage.data.picture)
      if (fetchHeaderImage.data.picture)
        setHeaderImage(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchHeaderImage.data.picture}`
        );
    }
  }, [fetchHeaderImage]);

  const previousHandle = () => {
    if (previousRef.current) {
      previousRef.current.handlePrevious();
    }
  };

  return (
    <div>
      <div
        className="quiz t-bg-white"
        style={
          {
            // backgroundImage: `linear-gradient(var(--secondary-10),var(--secondary-20))`,
          }
        }
      >
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="col-3 ps-2 me-auto">
            <Icon
              name="chevronLeft"
              color="#000"
              onClick={previousHandle}
              size="32"
            />
          </div>
          <div className="col-md-6 col-sm-4 text-center mx-md-auto">
            <img src={Logo} alt="" width={100} className="t-mx-auto" />
          </div>
          <div className="col-3 ps-2 ms-auto">&nbsp;</div>
        </div>
        <div className="t-flex t-flex-col t-gap-0 t-my-8">
          {showProgressBar && (
            <ProgressBar
              className="custom-progress-bar !t-mx-auto !t-w-[90%] md:!t-w-[500px] !t-border-[1px] !t-border-secondary !t-bg-[#f2f4f7]"
              style={{ width: "90%", height: "10px" }}
              now={progressValue}
            />
          )}
          {showProgressBar && (
            <div className="progressbar-label !t-mx-auto !t-w-full !t-text-black !t-text-center">{`${progressValue}%`}</div>
          )}
        </div>
        <div className="container">
          <QuizModule
            setProgressValue={setProgressValue}
            ref={previousRef}
            setShowProgressBar={setShowProgressBar}
            progressValue={progressValue}
          />
          <div style={{ height: "100%" }}>&nbsp;</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Quiz;
