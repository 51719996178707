import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Banner from "./Banner";
import ReviewsOverlay from "../../Reviews";
import PersonalFormulasSection from "../../Home/PersonalFormulasSection";
import { useTranslation } from "react-i18next";
import {
  CustomerReviews,
  FormulaHeading,
  FormulatedByExperts,
} from "../../../Constants";
import ExpertSection from "../../Customer/components/ExpertSection";
import Reviews from "../../Customer/components/Reviews";
import CustomLink from "../../CustomLink";
import Unique from "../../Home/Unique";
import HighStandards from "../../Home/HighStandards";
import MillionCombinations from "../../Home/MillionCombinations";
import { useGetContentsByTagWebQuery } from "../../../features/contentsApiSlice";
import productImg from "../../../assets/yellow-paper-pouch-granules.webp";
import UniqueWeb from "../../FocusAreas/components/UniqueWeb";

const GetTested = () => {
  const { t } = useTranslation();
  const [reviewData, setReviewData] = useState();
  const [picture13, setPicture13] = useState("");
  const [picture14, setPicture14] = useState("");
  const [picture15, setPicture15] = useState("");

  const [data, setData] = useState([]);

  const { data: fetchContents, isSuccess } = useGetContentsByTagWebQuery({
    tag: "home",
    lang: "en",
    skip: false,
  });

  useEffect(() => {
    if (isSuccess) {
      setData(fetchContents.data);
      if (fetchContents.data.length > 0) {
        setPicture13(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[12].picture}`
        );
        setPicture14(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[13].picture}`
        );
        setPicture15(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[14].picture}`
        );
      }
    }
  }, [fetchContents, isSuccess]);

  const bannerRef = useRef(null);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBannerVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const reviewsCount = document.querySelectorAll(
        ".cssVar-subheading__number"
      );
      if (reviewsCount) {
        const reviews = [];
        reviewsCount.forEach((element) => {
          reviews.push(element.innerHTML);
        });
        setReviewData(reviews);
      }
    });

    // Start observing the target node for DOM changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const sectionRef = useRef(null);
  const scrollToSectionEnd = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  return (
    <div className="t-mb-60">
      <Header showButton={!isBannerVisible} />
      <div className="t-w-full t-flex t-flex-col">
        <div ref={bannerRef} className="">
          <Banner />
        </div>
        <section className="t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100">
          <ReviewsOverlay
            totalReviews={reviewData?.[1]?.trim() ?? 47}
            averageReviews={reviewData?.[0]?.trim() ?? 4.93}
            onClick={scrollToSectionEnd}
          />
        </section>
        <div className="t-hidden md:t-block">
          <UniqueWeb />
        </div>
        <div className="md:t-hidden t-max-w-[100vw] t-flex t-items-center t-justify-center">
          <Unique />
        </div>
        <div className="t-w-full t-max-w-[600px] t-mx-auto t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4 t-p-4">
          <h1 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(FormulatedByExperts)}
          </h1>
          <ExpertSection />
        </div>
        <div className="t-flex t-items-center t-justify-center">
          <HighStandards />
        </div>
        <div className="t-flex t-items-center t-justify-center">
          <MillionCombinations slug="/quiz?partner_id=gettestedio" />
        </div>
        <section className="services section-padding !pb-0 t-bg-white t-h-[360px] md:t-h-[460px]">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title-wrapper">
                  <h3 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-text-tertiary">
                    {data[1] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[1]?.title }}
                      />
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="sm:t-hidden t-flex t-flex-row t-items-center t-gap-4 -t-mt-8">
              <img src={productImg} alt="" className="!t-h-[80%] !t-w-[30%]" />
              <div className="t-flex t-flex-col t-justify-between t-gap-8 t-mt-4 ">
                {data[12] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[12]?.title }}
                  />
                )}
                {data[13] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[13]?.title }}
                  />
                )}
                {data[14] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[14]?.title }}
                  />
                )}
              </div>
            </div>
            <div className="!t-hidden sm:!t-flex row gy-4 t-items-start t-justify-center">
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture15}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[12] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[12]?.title }}
                      />
                    )}
                    {data[12] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[12]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture14}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[13] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[13]?.title }}
                      />
                    )}
                    {data[13] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[13]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture13}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[14] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[14]?.title }}
                      />
                    )}
                    {data[14] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[14]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          ref={sectionRef}
          className="t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4 t-p-4 t-bg-gray-100"
        >
          <h1 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(CustomerReviews)}
          </h1>
          <div className="t-w-full t-max-w-[1330px] t-mx-auto">
            <Reviews />
          </div>
        </div>
        <div className="t-flex t-flex-col t-gap-4 t-bg-white t-justify-center t-items-center t-p-4">
          <h1 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(FormulaHeading)}
          </h1>
          <PersonalFormulasSection />
          <CustomLink to="/quiz?partner_id=gettestedio">
            <button className="btn !t-w-fit !t-bg-primary t-text-black !t-rounded-md">
              Personalize mine
            </button>
          </CustomLink>
        </div>
      </div>
    </div>
  );
};

export default GetTested;
