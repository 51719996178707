import React, { useState } from "react";
import RefillBanner from "../assets/refill-bg-1.webp";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RefillBannerHeading,
  RefillBannerDesc1,
  RefillBannerDesc2,
  RefillBannerDesc3,
  RefillBannerDesc4,
  RefillInputPlaceholder,
  RefillBtnText,
  PhaseHeading,
  StartPhaseHeading,
  StartPhaseDesc1,
  StartPhaseDesc2,
  StartPhaseDuration,
  StartPhaseDurationTime,
  RoutinePhaseHeading,
  RoutinePhaseDesc1,
  RoutinePhaseDesc2,
  RoutinePhaseDurationTime,
  SuccessPhaseHeading,
  SuccessPhaseDesc1,
  SuccessPhaseDesc2,
  SuccessPhaseDurationTime,
  HelpPhaseHeading,
  HelpPhaseDesc1,
  HelpPhaseDesc2,
} from "../Constants";
import { useTranslation } from "react-i18next";
import HereForYou from "./Customer/components/HereForYou";

const Refill = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState(null);
  const navigate = useNavigate();
  const navigateToPage = () => {
    if (!code) {
      toast.error("Code is required!");
    } else {
      navigate(`/quiz?quizId=${code}`);
    }
  };

  const data = [
    {
      heading: t(StartPhaseHeading),
      desc1: t(StartPhaseDesc1),
      desc2: t(StartPhaseDesc2),
      duration: t(StartPhaseDurationTime),
    },
    {
      heading: t(RoutinePhaseHeading),
      desc1: t(RoutinePhaseDesc1),
      desc2: t(RoutinePhaseDesc2),
      duration: t(RoutinePhaseDurationTime),
    },
    {
      heading: t(SuccessPhaseHeading),
      desc1: t(SuccessPhaseDesc1),
      desc2: t(SuccessPhaseDesc2),
      duration: t(SuccessPhaseDurationTime),
    },
  ];

  return (
    <div>
      <div className="t-relative t-flex t-items-center t-justify-center">
        <img
          src={RefillBanner}
          alt=""
          className="t-w-[100vw] t-h-[500px] md:t-h-auto t-max-h-[800px] t-object-cover"
        />
        <div
          className=" t-absolute t-w-[100vw] t-h-[500px] md:t-h-[800px] t-object-cover t-z-10"
          style={{ background: "rgba(0,0,0,0.3)" }}
        ></div>
        <div className="t-max-w-[1330px] t-w-full t-h-full t-absolute t-z-20 !t-text-white t-top-0 t-flex t-flex-col t-items-center md:t-items-start t-justify-center t-gap-2 md:t-gap-4 t-p-4 md:t-p-8">
          <h1
            className="t-font-extrabold t-m-0 t-text-center"
            style={{ fontFamily: "Roboto" }}
          >
            {t(RefillBannerHeading)}
          </h1>
          <p className="t-text-sm md:t-text-lg t-m-0 t-text-center">
            {t(RefillBannerDesc1)}
          </p>
          <p className="t-text-sm md:t-text-lg t-m-0 t-text-center">
            {t(RefillBannerDesc2)}
          </p>
          <p className="t-text-sm md:t-text-lg t-m-0 t-text-center t-mt-44">
            {t(RefillBannerDesc3)}
          </p>
          <input
            type="text"
            className="form-control t-h-[40px] t-max-w-[300px] !t-pl-3 !t-bg-gray-300 !t-text-center !t-rounded-lg"
            placeholder={t(RefillInputPlaceholder)}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            minLength={3}
          />
          <button
            type="button"
            className="col-12 btn btn-custom-secondary !t-bg-primary !t-text-secondary t-max-w-[300px] fw-bold lts1 px-5 !t-rounded-lg"
            onClick={navigateToPage}
          >
            {t(RefillBtnText)}
          </button>
          <p className="t-text-sm md:t-text-lg t-m-0 t-text-center">
            {t(RefillBannerDesc4)}
          </p>
        </div>
      </div>
      <div className="t-flex t-flex-col t-gap-8 t-bg-gray-100 t-p-4 md:t-p-12">
        <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
          {t(PhaseHeading)}
        </h3>
        <div className="t-flex t-flex-col md:t-flex-row t-gap-4 t-max-w-[1330px] t-mx-auto">
          {data.map((item, i) => (
            <Card
              key={"refill-" + i}
              count={i + 1}
              durationHeading={t(StartPhaseDuration)}
              {...item}
            />
          ))}
        </div>
      </div>
      <section className="steps section-padding t-max-w-[700px] t-mx-auto !t-pb-8 !t-bg-white !t-px-4 !t-py-8 !t-mb-16">
        <div className="t-mb-8 t-flex t-flex-col t-gap-4">
          <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
            {t(HelpPhaseHeading)}
          </h3>
          <p className="t-m-0 t-text-sm t-text-center">{t(HelpPhaseDesc1)}</p>
          <p className="t-m-0 t-text-sm t-text-center">{t(HelpPhaseDesc2)}</p>
        </div>

        <HereForYou />
      </section>
    </div>
  );
};

const Card = ({ heading, desc1, desc2, duration, count, durationHeading }) => (
  <div className="t-w-full t-max-w-[300px] t-flex t-flex-col t-gap-4 t-p-4 t-bg-gray-300 t-rounded-lg">
    <div className="t-flex t-items-center t-gap-2">
      <p className="t-flex-none t-h-10 t-w-10 t-flex t-items-center t-justify-center t-m-0 t-p-1 t-bg-secondary t-rounded-full t-text-white t-font-bold t-text-xl">
        {count}
      </p>
      <p
        className="t-m-0 t-font-bold t-text-xl t-text-secondary"
        style={{ fontFamily: "Roboto" }}
      >
        {heading}
      </p>
    </div>
    <p className="t-m-0 t-text-secondary t-leading-5">{desc1}</p>
    <p className="t-m-0 t-text-secondary">{desc2}</p>
    <p className="t-m-0 t-text-secondary t-mt-auto">
      <span className="t-font-extrabold" style={{ fontFamily: "Roboto" }}>
        {durationHeading}
        {": "}
      </span>
      {duration}
    </p>
  </div>
);

export default Refill;
