import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  customerQuizDetailsAction,
  getOrderDetails,
} from "../../app/api/useDataManage/userActions";
import DashboardWrapper from "./components/DashboardWrapper";
import CustomLink from "../CustomLink";

function OrderDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const [token] = useState(localStorage.getItem("customer_token"));

  const receivedData = location.state;
  const { quizDetails, orderDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const reqData = {
      token: token,
      quizData: {
        lang: "en",
        customer_quiz_id: location.state.customer_quiz_id,
      },
    };
    const orderData = {
      token: token,
      id: location.state.id,
    };
    dispatch(customerQuizDetailsAction(reqData));
    dispatch(getOrderDetails(orderData));
  }, [dispatch, location.state, token]);

  const handleRenewOrder = () => {
    const data = {
      quiz_id: receivedData.customer_quiz_id,
      renewal: true,
    };
    navigate("/product-suggestion", { state: data });
  };

  return (
    <DashboardWrapper title={"Order Details"}>
      <div className="col-xl-8 col-lg-8 user-inner ps-0">
        <div className="profile-reviews-wrapper">
          <div className="container ps-0">
            <div className="row t-mb-6">
              <div className="col-6">
                <div className="welcome mb-1">
                  <h4>Order Details </h4>
                </div>
              </div>
              <div className="col-6 text-end">
                <div className="welcome mb-1">
                  <button
                    type="button"
                    className="btn btn-custom-secondary fw-bold lts1 t-px-2 md:t-px-4"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            {/* order Detail Heading  */}

            <div className="container px-2">
              <div className="row">
                <div className="col">
                  <div className="container p-1">
                    <h5 className="t-mx-0">Order Info</h5>
                    <div className="row py-2 t-bg-gray-100">
                      <div className="col">
                        <h5 className="text-center t-mb-0">Order</h5>
                      </div>
                      <div className="col">
                        <h5 className="text-center t-mb-0">Invoice Date</h5>
                      </div>
                      <div className="col">
                        <h5 className="text-center t-mb-0">Total</h5>
                      </div>
                    </div>
                    <div className="py-2">
                      {orderDetails?.map((item, key) => (
                        <div className="row align-items-center" key={key}>
                          <div className="col ml-4 text-center">
                            #{item.orderId}
                          </div>
                          <div className="col text-center">
                            {receivedData.invoice_date
                              ? new Date(
                                  receivedData.invoice_date
                                ).toLocaleDateString()
                              : new Date(item.createdAt).toLocaleDateString()}
                          </div>
                          <div className="col text-center pb-2 ">
                            {item.totalPrice}
                          </div>
                          <hr className="ps-0 m-1" />
                          <div className="col t-py-1">
                            <span className="fw-semibold">Status: </span>
                            {item.status}
                            <br />
                            {item?.details?.includes("https") ? (
                              <CustomLink to={item?.details} target="_blank">
                                Tracking Link
                              </CustomLink>
                            ) : (
                              <p className="!t-m-0">
                                {" "}
                                {item?.details ? item.details : "-"}
                              </p>
                            )}
                          </div>

                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="container  p-2">
                    <div className="row py-1 t-bg-gray-100">
                      <h5 className="t-mb-0">Subscription Info</h5>
                    </div>
                    <div className="py-2">
                      {orderDetails?.map((item, key) => (
                        <div className="" key={key}>
                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold">Title: </span>
                            {item.title}
                          </div>
                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold">Description: </span>
                            {item.description}
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="container  p-2">
                    <div className="row py-1 t-bg-gray-100">
                      <h5 className="t-mb-0">Shipping Info</h5>
                    </div>
                    <div className="py-2">
                      {orderDetails?.map((item, key) => (
                        <div className="" key={key}>
                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold">Address: </span>
                            {item.address}
                          </div>
                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold">Phone: </span>
                            {item.phoneNo}
                          </div>
                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold ">State: </span>
                            {item.state}
                          </div>

                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold">Zip Code: </span>
                            {item.zipCode}
                          </div>
                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold">Country: </span>
                            {item.country}
                          </div>
                          <div className="col ml-4r mt-1">
                            <span className="fw-semibold">Email: </span>
                            {item.email}
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="table-responsive">
                    <table className="table accordion" id="accordionExample">
                      <thead>
                        <tr>
                          <th scope="col" className="col-6">
                            Nutrient
                          </th>
                          <th scope="col">Quantity</th>
                          <th scope="col">NRV%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quizDetails &&
                          quizDetails.map((item) => (
                            <tr>
                              <td>{item.ingredient}</td>
                              <td>{item.daily_dose} mg</td>
                              <td>
                                {item.per_eu_nrv === 0
                                  ? "*"
                                  : `${item.per_eu_nrv}%`}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-detail-inner"></div>
      </div>
    </DashboardWrapper>
  );
}

export default OrderDetails;
