import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { cartActions } from "../../app/api/cart/cartReducer";
import {
  getCustomerReferrerByIdActions,
  getOrderAction,
  getReferreCodeAction,
} from "../../app/api/cart/cartActions";
import { getCustomerProfileFirstAction } from "../../app/api/useDataManage/userActions";
import {
  CheckOut_,
  CheckOut__,
  YourSupplements,
  YourOrder,
  SubTotal,
  AfterDiscount,
  Shipping,
  Total,
  Submit,
  CapsulePerDay,
} from "../../Constants";
import { selectCurrentLanguage } from "../../features/langSlice";
import { useTranslation } from "react-i18next";
import CustomLink from "../CustomLink";

const url = `${process.env.REACT_APP_BASE_URL}`;
function CartScreen() {
  const history = useNavigate();
  const selectedLang = useSelector(selectCurrentLanguage);
  const { t } = useTranslation();
  const [lang, setLang] = useState();

  const dispatch = useDispatch();
  const {
    cart,
    subscription,
    userOrderCheck,
    referrer_codes,
    customer_referal_Code,
  } = useSelector((state) => state.cart);

  const { user, customerProfile } = useSelector((state) => state.user);
  const [token] = useState(localStorage.getItem("customer_token"));
  const [quizId] = useState(localStorage.getItem("quiz_id"));
  const [error, setError] = useState("");
  const [product, setProduct] = useState();
  const [discount, setDiscount] = useState();
  const [codeType, setCodeType] = useState();
  const [fetcheReferalData, setFetchedReferal] = useState();

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);
  }, [lang, selectedLang]);

  useEffect(() => {
    //dispatch(getCustomerProfileAction(token));
    dispatch(getCustomerProfileFirstAction({ quiz_id: quizId }));
    dispatch(getOrderAction(quizId));
    dispatch(getReferreCodeAction());
  }, [dispatch, token]);

  useEffect(() => {
    const subscriptionJSON = localStorage.getItem("subscription");
    const productJSON = localStorage.getItem("product");
    const storedSubscription = JSON.parse(subscriptionJSON);
    dispatch(cartActions.getSubscription(storedSubscription));
    const storedProduct = JSON.parse(productJSON);

    dispatch(cartActions.getCartItem(storedProduct));

    setProduct(storedProduct);
  }, [dispatch]);

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    return originalPrice - (originalPrice * discountPercentage) / 100;
  };
  useEffect(() => {
    if (customerProfile !== null && customer_referal_Code === null) {
      dispatch(getCustomerReferrerByIdActions(customerProfile?.id));
    }
  }, [customerProfile, dispatch, customer_referal_Code]);

  useEffect(() => {
    if (customer_referal_Code !== undefined && customer_referal_Code !== null) {
      let data = { ...cart };

      setProduct(data);

      setFetchedReferal(data);

      const discountPrice = calculateDiscountedPrice(
        Number(cart?.subTotal),
        Number(customer_referal_Code?.discount_per)
      );

      const fixed = discountPrice.toFixed(2);
      const total = Number(cart?.shippingCharges) + Number(fixed);

      setFetchedReferal({
        ...data,
        subTotal: fixed,
        total: total,
      });
      setProduct({
        ...data,
        subTotal: fixed,
        total: total,
      });
      setCodeType({
        refereer: true,
      });
    } else {
      setCodeType({
        refereer: false,
      });
    }
  }, [customer_referal_Code, dispatch, cart]);

  const onSubmitCheckout = (e) => {
    e.preventDefault();
    const cartData = {
      cart: product,
      discountData: codeType,
    };

    history("/checkout", { state: cartData });
  };

  // const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
  //   return originalPrice - (originalPrice * discountPercentage) / 100;
  // };

  const referalCodeHandler = async () => {
    if (cart.subTotal !== product.subTotal || cart.total !== product.total) {
      setProduct({ ...product, subTotal: cart.subTotal, total: cart.total });
      product.subTotal = cart.subTotal;
      product.total = cart.total;
    }
    if (codeType?.refereer === true) {
      product.subTotal = fetcheReferalData.subTotal;
      product.total = fetcheReferalData.total;
      setProduct({ ...product });
      return;
    }

    await axios
      .post(`${url}getCustomerByReferal`, { referral_code: discount })
      .then(async (res) => {
        let custom = res.data.customer;

        if (custom !== null && customerProfile.id !== custom.id) {
          let { data } = await axios.post(`${url}checkReferalCode`, {
            customer_referrer_id: custom.id,
            customer_referee_id: customerProfile.id,
          });

          let refere = data.referre;
          if (refere === null) {
            const discountPrice = calculateDiscountedPrice(
              Number(product.subTotal),
              Number(referrer_codes.discount_per)
            );
            let total = Number(product.shippingCharges) + discountPrice;
            setProduct({ ...product, subTotal: discountPrice, total: total });
            setCodeType({
              referralCode: true,
              refereer: false,
              customer_referrer_id: custom.id,
              customer_referee_id: customerProfile.id,
              discount_per: referrer_codes.discount_per,
              discountCode: false,
            });
          }
          if (refere !== null) {
            setError("Referall Code already Used");
            return;
          }
        } else if (custom === null) {
          setError("Invalid referal Code");
        }

        return;
      })
      .catch((error) => {
        setProduct({ ...product });
        setError("Not Valid Referal Code", error.message);
        return;
      });
  };

  const discountHandler = async () => {
    setError("");
    if (discount?.includes("RIISE_ALE")) {
      await referalCodeHandler();
    }
    if (discount?.includes("CODE") || discount?.includes("code")) {
      if (cart.subTotal !== product.subTotal || cart.total !== product.total) {
        setProduct({ ...product, subTotal: cart.subTotal, total: cart.total });
        product.subTotal = cart.subTotal;
        product.total = cart.total;
      }
      if (codeType?.refereer === true) {
        product.subTotal = fetcheReferalData.subTotal;
        product.total = fetcheReferalData.total;
      }

      await axios
        .post(`${url}getDiscounts`, { code: discount })
        .then(async (response) => {
          let fetched = response.data.discounts;

          if (fetched.active === 0) {
            setProduct({ ...product });
            setError("This Discount is not active at the moment.");
            return;
          } else {
            setError("");
          }
          if (
            userOrderCheck.counts !== 0 &&
            fetched.discount_type === "First Purchase"
          ) {
            setProduct({ ...product });
            setError("Existing User can not use it");
            return;
          }

          await fetch(`${url}getUserDiscount`, {
            method: "POST", // Assuming you are sending data and expecting a response
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              discount_code: discount,
              user_id: customerProfile.user_id,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              let response = data.message;

              if (response === "not Found") {
                if (fetched.cumulable === 0 && codeType?.refereer === true) {
                  setProduct({ ...product });
                  setError("Cannot apply multiple Codes");
                  return;
                }
                if (
                  userOrderCheck.counts === 0 &&
                  fetched.discount_type === "First Purchase" &&
                  fetched.applicable_to === "All Purchases"
                ) {
                  if (new Date(fetched.valid_to) < new Date(Date.now())) {
                    setProduct({ ...product });
                    setError("discount has  Expired");
                    return;
                  }

                  const discountPrice = calculateDiscountedPrice(
                    Number(product.subTotal),
                    Number(fetched.discount_value)
                  );
                  let total =
                    Number(product.shippingCharges) + Number(discountPrice);
                  setProduct({
                    ...product,
                    subTotal: discountPrice.toFixed(2),
                    total: total,
                  });
                  setCodeType({
                    referralCode: false,
                    refereer: codeType?.refereer === true ? true : false,
                    discountCode: true,
                    userId: user.id,
                    discountId: fetched.id,
                    discount_code: discount,
                  });
                  return;
                }

                if (
                  (subscription.duration.includes("Month") &&
                    fetched.discount_type.includes(
                      "First Purchase & Renewals"
                    ) &&
                    fetched.applicable_to.includes("Monthly Subscription")) ||
                  (product.renewal === true &&
                    fetched.discount_type.includes(
                      "First Purchase & Renewals"
                    ) &&
                    fetched.applicable_to.includes("Monthly Subscription"))
                ) {
                  if (new Date(fetched.valid_to) < new Date(Date.now())) {
                    setProduct({ ...product });
                    setError("discount Expired");
                    return;
                  }

                  const discountPrice = calculateDiscountedPrice(
                    Number(product.subTotal),
                    Number(fetched.discount_value)
                  );
                  let total =
                    Number(product.shippingCharges) + Number(discountPrice);
                  setProduct({
                    ...product,
                    subTotal: discountPrice.toFixed(2),
                    total: total,
                  });

                  setCodeType({
                    referralCode: false,
                    refereer: codeType.refereer === true ? true : false,
                    discountCode: true,
                    userId: user.id,
                    discountId: fetched.id,
                    discount_code: discount,
                  });
                  return;
                }

                if (
                  userOrderCheck.counts === 0 &&
                  subscription.duration.includes("Month") &&
                  fetched.discount_type.includes("First") &&
                  fetched.applicable_to.includes("Monthly Subscription")
                ) {
                  if (new Date(fetched.valid_to) < new Date(Date.now())) {
                    setProduct({ ...product });
                    setError("discount Expired");
                    return;
                  }

                  const discountPrice = calculateDiscountedPrice(
                    Number(product.subTotal),
                    Number(fetched.discount_value)
                  );
                  let total =
                    Number(product.shippingCharges) + Number(discountPrice);
                  setProduct({
                    ...product,
                    subTotal: discountPrice.toFixed(2),
                    total: total,
                  });

                  setCodeType({
                    referralCode: false,
                    refereer: codeType?.refereer === true ? true : false,
                    discountCode: true,
                    userId: user.id,
                    discountId: fetched.id,
                    discount_code: discount,
                  });
                  return;
                }

                if (
                  userOrderCheck.counts === 0 &&
                  subscription.duration.includes("3 Month") &&
                  fetched.discount_type.includes("First") &&
                  fetched.applicable_to.includes("3-Month Subscription")
                ) {
                  if (new Date(fetched.valid_to) < new Date(Date.now())) {
                    setProduct({ ...product });
                    setError("discount Expired");
                    return;
                  }

                  const discountPrice = calculateDiscountedPrice(
                    Number(product.subTotal),
                    Number(fetched.discount_value)
                  );

                  let total =
                    Number(product.shippingCharges) + Number(discountPrice);
                  setProduct({
                    ...product,
                    subTotal: discountPrice.toFixed(2),
                    total: total,
                  });
                  setCodeType({
                    referralCode: false,
                    refereer: codeType?.refereer === true ? true : false,
                    discountCode: true,
                    userId: user.id,
                    discountId: fetched.id,
                    discount_code: discount,
                  });
                  return;
                }
                if (
                  subscription.duration.includes("Month") &&
                  fetched.applicable_to.includes("Subscriptions Only")
                ) {
                  if (new Date(fetched.valid_to) < new Date(Date.now())) {
                    setProduct({ ...product });
                    setError("discount Code has  Expired");
                    return;
                  }

                  const discountPrice = calculateDiscountedPrice(
                    Number(product.subTotal),
                    Number(fetched.discount_value)
                  );
                  let total =
                    Number(product.shippingCharges) + Number(discountPrice);
                  setProduct({
                    ...product,
                    subTotal: discountPrice.toFixed(2),
                    total: total,
                  });
                  setCodeType({
                    referralCode: false,
                    refereer: codeType?.refereer === true ? true : false,
                    discountCode: true,
                    userId: user.id,
                    discountId: fetched.id,
                    discount_code: discount,
                  });
                  return;
                } else {
                  setProduct({ ...product });
                }
              }
              if (response === "Found") {
                setProduct({ ...product });
                setError(" Discount Code has already Used ");
              }
            });
        })
        .catch((error) => {
          setProduct({ ...product });
          setError("Invalid discount Code");
          return;
        });
    }
  };
  return (
    <div>
      {cart !== null ? (
        <main className="checkout-page">
          <section className="breadcrumbs-wrapper">
            <div className="container">
              <nav aria-label="Page breadcrumb">
                <h2 className="title2">{t(CheckOut_)}</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    <CustomLink to={"/"} className="breadcrumb-item">
                      Home
                    </CustomLink>
                  </li>
                  <li className="breadcrumb-item">{t(YourSupplements)}</li>
                </ol>
              </nav>
            </div>
          </section>
          <section className="checkout-section section-padding">
            <div className="container">
              <div className="row gy-4">
                <div className="col-xl-8">
                  <div className="card checkout-item">
                    <div className="card-header">
                      <h1 className="display-5">{t(YourSupplements)}</h1>
                    </div>
                    <div className="card-body">
                      <div className="row align-items-center gy-4">
                        <div className="col-sm-auto">
                          <img
                            src="assets/imgs/landing-page1/calcium.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="col">
                          <h1 className="display-5">{cart?.name}</h1>
                          <p>1 {t(CapsulePerDay)}</p>
                          <ul>
                            <li>
                              <span /> Vitamin A
                            </li>
                            <li>
                              <span /> Vitamin B
                            </li>
                            <li>
                              <span /> Vitamin C
                            </li>
                          </ul>
                          <h2 className="price mt-4">{cart?.price} $</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="box-style-2 checkout-box sticky-top">
                    <div className="checkout-header">
                      <h1 className="display-4 m-0">{t(YourOrder)}</h1>
                      <h5>For {subscription?.title}</h5>
                    </div>
                    <div className="checkout-body">
                      <div className="row">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>{t(SubTotal)}</td>
                              <td align="right">{cart?.subTotal} $</td>
                            </tr>
                            {product?.subTotal !== cart?.subTotal && (
                              <tr>
                                <td>{t(AfterDiscount)}</td>
                                <td align="right">{product?.subTotal} $</td>
                              </tr>
                            )}
                            <tr>
                              <td>{t(Shipping)}</td>
                              <td align="right">{cart?.shippingCharges}$</td>
                            </tr>
                            <tr>
                              <td>
                                <b>{t(Total)}</b>
                              </td>
                              <td align="right">
                                <b>{product?.total} $</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="checkout-footer">
                      <div className="coupan-box">
                        <input
                          type="text"
                          name="discount"
                          id="discount"
                          className="form-control"
                          placeholder="Coupon Coude"
                          aria-describedby="helpId"
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                        />
                        <input
                          className="btn"
                          type="submit"
                          onClick={discountHandler}
                          value={t(Submit)}
                        />
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        {error && (
                          <p style={{ color: "red", textAlign: "center" }}>
                            {error}
                          </p>
                        )}
                      </div>
                      <button
                        type="button"
                        className="btn btn-custom-1 w-100 mt-5"
                        onClick={onSubmitCheckout}
                      >
                        {t(CheckOut__)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      ) : (
        <div>
          <h3>No Items in Cart</h3>
        </div>
      )}
    </div>
  );
}

export default CartScreen;
