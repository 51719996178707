import React from "react";
import PartnerLogo from "../../../assets/gettested-logo.webp";
import { logo } from "../../../Data/Images/Home";
import CustomLink from "../../CustomLink";

const Header = ({ showButton }) => {
  return (
    <div className="t-sticky t-top-0 t-w-full t-bg-secondary">
      <div className="t-relative !t-max-w-[1330px] !t-mx-auto t-flex t-items-center  t-py-2 t-px-4 t-z-20">
        <CustomLink
          to="/quiz?partner_id=gettestedio"
          className="t-absolute t-left-8 t-hidden lg:t-block"
        >
          <button className="!t-w-[150px] !t-bg-primary t-text-black !t-rounded-md t-px-2 t-py-1">
            Take the test
          </button>
        </CustomLink>
        <div className="t-w-full t-flex t-items-end lg:t-justify-center t-gap-1">
          <img src={logo} alt="" className="t-w-1/5 md:t-w-[5%]" />
          <h1 className="!t-text-white !t-font-extrabold !t-text-base ">x</h1>
          <img src={PartnerLogo} alt="" className="t-w-1/3 md:t-w-[9%]" />
        </div>
      </div>
    </div>
  );
};

export default Header;
