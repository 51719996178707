import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";

const URL = `${process.env.REACT_APP_BASE_URL}my-server`;
function PayPalPayment({ onPayment, price }) {
  const { gatwayInfo } = useSelector((state) => state.user);

  const paypalData = gatwayInfo.filter((item) => item.company === "paypal");
  const initialOptions = {
    "client-id": paypalData[0].client_id,
    currency: paypalData[0].currency === "dollar" ? "USD" : "EUR",
    intent: "capture",
  };
  const createOrder = async (data) => {
    // Order is created on the server and the order id is returned
    return await fetch(`${URL}/create-paypal-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // use the "body" param to optionally pass additional order information
      // like product skus and quantities
      body: JSON.stringify({
        cart: [
          {
            sku: "001",
            price: `${price}`,
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((order) => order.id);
  };
  const onApprove = (data) => {
    // Order is captured on the server and the response is returned to the browser
    try {
      return fetch(`${URL}/capture-paypal-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
        }),
      }).then(async (response) => {
        const result = await response.json();
        onPayment({
          transactionId: result.id,
          paymentJson: result,
          subscriptionId: "",
          customerId: "",
        });
      });
    } catch (error) {
      onPayment({
        error: error.message,
      });
    }
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
      />
    </PayPalScriptProvider>
  );
}

export default PayPalPayment;
