import React, { useEffect, useState } from "react";
import { getUsersOrderActions } from "../../app/api/useDataManage/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "./components/DashboardWrapper";
import CustomLink from "../CustomLink";

function CustomerOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("customer_token"));
  const { userOrders } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUsersOrderActions(token));
  }, [dispatch, token]);

  const handleClick = (orderData) => {
    navigate("/dashboard/orderDetails", { state: orderData });
  };

  return (
    <DashboardWrapper title={"Orders"}>
      <div className="col-xl-8 col-lg-8 user-inner">
        <div className="profile-reviews-wrapper">
          <div className="container">
            <div className="welcome t-mb-6">
              <h4 className="t-font-bold">User Orders </h4>
            </div>

            {/* Order Heading  */}

            {userOrders?.length > 0 ? (
              <div class="d-flex flex-row mb-3 justify-content-between align-items-start mx-auto  t-bg-gray-100">
                <div class="p-2 t-pl-3 t-w-1/2 t-text-center">
                  <h5>Order</h5>
                </div>
                <div class="p-2  t-w-1/2 t-text-center">
                  <h5>Invoice Date</h5>
                </div>
                <div class="p-2  t-w-1/2 t-text-center">
                  <h5>Status</h5>
                </div>
                <div class="p-2  t-w-1/2 t-text-center">
                  <h5>Tracking</h5>
                </div>
                <div class="p-2  t-w-1/2 t-text-center">
                  <h5>Total</h5>
                </div>
                <div class="p-2  t-w-1/2 t-text-center">
                  <h5>Actions</h5>
                </div>
              </div>
            ) : (
              <p>There are no Order to display.</p>
            )}

            {userOrders?.map((item, key) => (
              <div key={key}>
                <div key={key} class="d-flex flex-row justify-content-between">
                  <div class="p-2 t-w-1/2 t-text-center t-items-center">
                    <p className="!t-m-0"> #{item.id}</p>
                  </div>
                  <div class="p-2  t-w-1/2 t-text-center t-items-center">
                    <p className="!t-m-0">
                      {item.invoice_date
                        ? new Date(item.invoice_date).toLocaleDateString()
                        : new Date(item.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="p-2  t-w-1/2 t-text-center t-items-center">
                    <p className="!t-m-0"> {item.status}</p>
                  </div>
                  <div className="p-2  t-w-1/2 t-text-center t-items-center">
                    {item?.details?.includes("https") ? (
                      <CustomLink to={item?.details} target="_blank">
                        Tracking Link
                      </CustomLink>
                    ) : (
                      <p className="!t-m-0">
                        {" "}
                        {item?.details ? item.details : "-"}
                      </p>
                    )}
                  </div>
                  <div className="p-2  t-w-1/2 t-text-center t-items-center">
                    <p className="!t-m-0"> €{item.totalPrice}</p>
                  </div>
                  <div class="pt-1  t-w-1/2 t-text-center t-items-center">
                    <input
                      type="submit"
                      placeholder="View"
                      value={"View"}
                      className="t-bg-secondary t-px-4 t-text-xs t-h-[25px] t-rounded-full t-text-white"
                      onClick={handleClick.bind(this, item)}
                    />
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
        <div className="blog-detail-inner"></div>
      </div>
    </DashboardWrapper>
  );
}

export default CustomerOrder;
