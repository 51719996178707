import React from "react";

const Button = ({ index, component, onClick }) => {
  const handleClick = (e) => {
    // Call the onClick prop when the button is clicked and pass the component as an argument
    onClick(e, component);
  };

  return (
    <>
      <button
        key={index}
        className={`${component.class} ${
          ["Next", "Weiter"].includes(component.label) &&
          component.action === "next"
            ? "!t-hidden md:!t-flex t-items-center t-justify-center !t-mx-auto"
            : ""
        } ${
          !["Next", "Weiter", "Show the formula", "Formel anzeigen"].includes(
            component.label
          )
            ? "!t-bg-[#f2f4f7] !t-text-black hover:!t-border-2 hover:!t-text-black"
            : "!t-bg-secondary !t-text-white hover:!t-bg-quaternary hover:!t-text-white"
        } !t-rounded-lg !t-border-0`}
        onClick={handleClick}
      >
        <div dangerouslySetInnerHTML={{ __html: component.label }} />
      </button>
      {["Next", "Weiter"].includes(component.label) &&
        component.action === "next" && (
          <div
            className="md:t-hidden t-fixed t-bottom-0 t-left-0 !t-w-full t-bg-white t-p-4 t-rounded-[20px_20px_0_0]"
            style={{ boxShadow: "0 0 10px 10px #efefef" }}
          >
            <button
              key={index}
              className={`${component.class} !t-w-[80vw] !t-h-[50px] !t-rounded-lg !t-m-0 !t-bg-secondary hover:!t-text-white`}
              onClick={handleClick}
            >
              Next
            </button>
          </div>
        )}
    </>
  );
};

export default Button;
