export const FOCUS_AREA_LINKS = {
  "Sport Performance": "https://www.weriise.co/for-your-sport-performance",
  Energy: "https://www.weriise.co/for-your-energy",
  Focus: "https://www.weriise.co/for-your-focus",
  Mood: "https://www.weriise.co/for-your-mood",
  Sleep: "https://www.weriise.co/for-your-sleep",
  Stress: "https://www.weriise.co/for-your-stress",
  Digestion: "https://www.weriise.co/blogs/digestion-riise-supplement",
  "Immune System": "https://www.weriise.co/for-your-immune-system",
  Skin: "https://www.weriise.co/blogs/skin-riise-supplement",
  Hair: "https://www.weriise.co/blogs/hair-riise-supplement",
  Nails: "https://www.weriise.co/blogs/nails-riise-supplement",
  Heart: "https://www.weriise.co/blogs/heart-riise-supplement",
  "Muscle Pain": "https://www.weriise.co/blogs/muscle-pain-riise-supplement",
  Eyes: "https://www.weriise.co/blogs/eyes-riise-supplement",
  "Bones and Joints":
    "https://www.weriise.co/blogs/bones-and-joints-riise-supplement",
  "Sportliche Leistung": "https://www.weriise.co/for-your-sport-performance",
  Energie: "https://www.weriise.co/for-your-energy",
  Schwerpunkt: "https://www.weriise.co/for-your-focus",
  Stimmung: "https://www.weriise.co/for-your-mood",
  Schlaf: "https://www.weriise.co/for-your-sleep",
  Verdauung: "https://www.weriise.co/blogs/digestion-riise-supplement",
  Immunsystem: "https://www.weriise.co/for-your-immune-system",
  Haut: "https://www.weriise.co/blogs/skin-riise-supplement",
  Haare: "https://www.weriise.co/blogs/hair-riise-supplement",
  Nägel: "https://www.weriise.co/blogs/nails-riise-supplement",
  Herz: "https://www.weriise.co/blogs/heart-riise-supplement",
  Muskelschmerzen: "https://www.weriise.co/blogs/muscle-pain-riise-supplement",
  Augen: "https://www.weriise.co/blogs/eyes-riise-supplement",
  "Knochen und Gelenke":
    "https://www.weriise.co/blogs/bones-and-joints-riise-supplement",
};

export const hashEmail = async (email) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const getFBP = () => {
  const match = document.cookie.match(/_fbp=([^;]+)/);
  return match ? match[1] : null;
};

export const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const [key, value] = cookie.split("=");
    if (key === name) {
      return decodeURIComponent(value);
    }
  }
  return null;
};
