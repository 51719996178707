import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  MillionCombinations1,
  MillionCombinations2,
  MillionCombinations3,
  QuizButton,
  RecommendationButton,
} from "../../Constants";
import CustomLink from "../CustomLink";

const MillionCombinations = ({ slug, isFocusArea }) => {
  const { t } = useTranslation();
  return (
    <div className="t-w-full t-flex t-flex-col t-items-center t-justify-center t-bg-primary t-p-4 sm:t-gap-4">
      <h2 className="t-text-center t-text-[24px] md:t-text-[30px]">
        {t(MillionCombinations1)} {t(MillionCombinations2)}
      </h2>
      <h1
        className="t-font-[900] t-text-[#29a999]"
        style={{ fontFamily: "Roboto" }}
      >
        {t(MillionCombinations3)}
      </h1>
      <CustomLink to={slug}>
        <span className="btn btn-custom-secondary !t-rounded-lg">
          {isFocusArea ? t(QuizButton) : t(RecommendationButton)}
        </span>
      </CustomLink>
    </div>
  );
};

export default MillionCombinations;
