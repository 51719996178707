import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const URL = `${process.env.REACT_APP_BASE_URL}`;
// export const createPayPalActions = createAsyncThunk(
//   "paypal/action",
//   async (orderData) => {
//     try {
//       const { data } = await axios.post(`${URL}paypalOrder`, orderData);
//       return data.message;
//     } catch (error) {
//       let message = error.response.data.message;
//       return message;
//     }
//   }
// );

export const createOrderActions = createAsyncThunk(
  "order/create",
  async (orderData) => {
    try {
      const { data } = await axios.post(`${URL}createOrder`, orderData);
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getUserDiscountsActions = createAsyncThunk(
  "user/discounts",
  async (code) => {
    try {
      const { data } = await axios.post(`${URL}getDiscounts`, { code });

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getOrderAction = createAsyncThunk("order/get", async (quiz_id) => {
  try {
    const { data } = await axios.post(`${URL}getUserOrder`, {
      quiz_id: quiz_id,
    });
    return data.orders;
  } catch (error) {}
});

export const userUsedDiscountAction = createAsyncThunk(
  "discount/user",
  async (data) => {
    try {
      const response = await axios.post(`${URL}createUserDiscount`, data);
      return response.data;
    } catch (error) {}
  }
);

export const getReferreCodeAction = createAsyncThunk(
  "referal/code",
  async () => {
    try {
      const { data } = await axios.get(`${URL}getreferrerCodes`);
      return data.referrer_codes;
    } catch (error) {}
  }
);

export const createUserReferalCodeAction = createAsyncThunk(
  ("referalCode/create",
  async (referalData) => {
    try {
      await axios.post(`${URL}createCustomerReferralCode`, referalData);
    } catch (error) {}
  })
);

export const updateUserReferalCodeAction = createAsyncThunk(
  ("referalCode/update",
  async (referalData) => {
    try {
      await axios.post(`${URL}updateCustomerReferralCode`, referalData);
    } catch (error) {}
  })
);

export const getCustomerReferrerByIdActions = createAsyncThunk(
  "referal/getCust",
  async (id) => {
    try {
      const { data } = await axios.get(`${URL}getCustomerReferrerById/${id}`);
      return data.customer_referal_Code;
    } catch (error) {}
  }
);
