import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logo2 } from "../../Data/Images/Home";
import {
  RecentPost,
  UsefulLinks,
  StayConnected,
  About,
  TermsConditions,
  Policy,
  FAQs,
  Blogs,
  ContactUs_,
  Partner,
} from "../../Constants";
import { useGetBlogsQuery } from "../../features/blogApiSlice";
import { useSelector } from "react-redux";
import {
  selectSlug,
  selectTakeTheTestTitle,
} from "../../features/contentsSlice";
import PaymentMethods from "../../assets/payment-methods.webp";
import Shipping from "../../assets/shipping-DHL.webp";
import Reviews from "../../assets/reviews.webp";
import CustomLink from "../../Components/CustomLink";
import { useMetaApiMutation } from "../../features/metaApiSlice";
import { getCookie, getFBP, hashEmail } from "../../utils";
import { v4 as uuidv4 } from "uuid";

const Footer = () => {
  const lang = "en";
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const takeTheTestTitle = useSelector(selectTakeTheTestTitle);
  const slug = useSelector(selectSlug);

  const { data: fetchBlogs, isSuccess } = useGetBlogsQuery({
    lang: lang,
    page: 1,
    rows: 3,
    category: "blog",
  });

  useEffect(() => {
    if (isSuccess) {
      setData(fetchBlogs.data);
    }
  }, [fetchBlogs]);

  const [metaApi] = useMetaApiMutation();
  const metaEvent = async (event_name) => {
    try {
      const email = localStorage.getItem("email");
      const external_id = sessionStorage.getItem("externalId");
      const fbp = getFBP();
      const fbc = getCookie("_fbc");
      const id = uuidv4();
      if (window.fbq) {
        window.fbq(
          "track",
          event_name,
          {},
          {
            eventID: id,
            user_data: {
              external_id,
              ...(email ? { em: await hashEmail(email) } : {}),
            },
          }
        );
      }
      metaApi({
        event_name,
        page: window.location.href,
        email,
        id,
        external_id,
        fbp,
        fbc,
      })
        .then((res) => {})
        .catch((error) => console.log("Error => ", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Fragment>
      <footer className="section-padding">
        <div className="container">
          <div className="row gy-4 gx-sm-5">
            <div className="col-lg-3">
              <div className="logo">
                <img
                  src={logo2}
                  alt="riise logo 2"
                  className="img-fluid"
                  width={120}
                />
              </div>
              <div className="mt-5">
                {/*<a className="btn btn-custom-tertiary" href={`${process.env.PUBLIC_URL}/quiz`}>{takeTheTestTitle}</a>*/}
                <CustomLink to={slug}>
                  <span className="btn btn-custom-tertiary !t-rounded-lg">
                    {takeTheTestTitle}
                  </span>
                </CustomLink>
              </div>
              {/*<h4>{t(SubscribeFirstPurchase)}</h4>*/}
              {/*<form>*/}
              {/*  <div className="mb-3">*/}
              {/*    /!* <label for="" class="form-label">Name</label> *!/*/}
              {/*    <input*/}
              {/*        type="text"*/}
              {/*        onChange={(e) => setEmail(e.target.value)}*/}
              {/*        className="form-control" placeholder={t(EnterEmailHere)} aria-describedby="helpId" />*/}
              {/*    {emailError === '' ? '' : <span className="text-danger">{emailError}</span>}*/}
              {/*    {emailMessage === '' ? '' : <span className="text-success">{emailMessage}</span>}*/}
              {/*  </div>*/}
              {/*  <button type="button" className="btn btn-custom w-100" onClick={handleSubscribe}>{t(SubscribeNow)}</button>*/}
              {/*</form>*/}
            </div>
            <div className="col-lg-3">
              <div className="footer-item">
                <h3 className="title2">{t(RecentPost)}</h3>
                {data.map((blog, index) => (
                  <a
                    key={index}
                    href={`${process.env.PUBLIC_URL}/blogs/${blog.slug}`}
                  >
                    <div className="recent-blog-item">
                      <div className="">
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}${blog.picture}`}
                          alt={blog.title || "Blog picture"}
                          width={96}
                        />
                      </div>
                      <div>
                        <p>{blog.contents.substring(0, 50)}</p>
                        <h6>{blog.bdate}</h6>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-item">
                <h3 className="title2">{t(UsefulLinks)}</h3>
                <ul className="useful-links t-flex t-flex-col t-gap-2">
                  <CustomLink
                    to="/about"
                    className="t-text-lg !t-text-black t-font-bold"
                    onClick={() => metaEvent("ViewContent")}
                  >
                    {t(About)}
                  </CustomLink>
                  <CustomLink
                    to="/impressum"
                    className="t-text-lg !t-text-black t-font-bold"
                  >
                    Impressum
                  </CustomLink>
                  <CustomLink
                    to="/legal"
                    className="t-text-lg !t-text-black t-font-bold"
                  >
                    {t(TermsConditions)}
                  </CustomLink>
                  <CustomLink
                    to="/policy"
                    className="t-text-lg !t-text-black t-font-bold"
                  >
                    {t(Policy)}
                  </CustomLink>
                  <CustomLink
                    to="/faqs"
                    className="t-text-lg !t-text-black t-font-bold"
                  >
                    {t(FAQs)}
                  </CustomLink>
                  <CustomLink
                    to="/blogs"
                    className="t-text-lg !t-text-black t-font-bold"
                  >
                    {t(Blogs)}
                  </CustomLink>
                  <CustomLink
                    to="/contactus"
                    className="t-text-lg !t-text-black t-font-bold"
                    onClick={() => metaEvent("Contact")}
                  >
                    {t(ContactUs_)}
                  </CustomLink>
                  <CustomLink
                    to="/partner"
                    className="t-text-lg !t-text-black t-font-bold"
                  >
                    {t(Partner)}
                  </CustomLink>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-item">
                <h3 className="title2">{t(StayConnected)}</h3>
                <ul className="social">
                  <li>
                    <a
                      href="https://www.instagram.com/we_riise/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  {/*<li><a href><i className="fab fa-twitter" /></a></li>*/}
                  {/*<li><a href><i className="fab fa-facebook-f" /></a></li>*/}
                  {/*<li><a href><i className="fab fa-youtube" /></a></li>*/}
                </ul>

                <img
                  src={PaymentMethods}
                  alt=""
                  className="img-fluid mt-4 t-w-[50%] md:t-w-[70%]"
                />
                <img
                  src={Shipping}
                  alt=""
                  className="img-fluid mt-4 t-w-1/3 md:t-w-1/3"
                />
                <img
                  src={Reviews}
                  alt=""
                  className="img-fluid mt-4 t-w-1/3 md:t-w-1/2"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
