import React, { useMemo, useState } from "react";
import {
  Cancel,
  CheckIn,
  FeelingText,
  Submit,
  TotalCheckIns,
} from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useCustomerCheckInMutation } from "../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import { getProfileActions } from "../../app/api/useDataManage/userActions";
import DashboardWrapper from "./components/DashboardWrapper";
import { Icon } from "../elements/icon";

const localizer = momentLocalizer(moment);

const isSameDate = (date1, date2) => {
  const itemDate = new Date(date1);
  const newDate = new Date(date2);

  const sameYear = itemDate.getFullYear() === newDate.getFullYear();
  const sameMonth = itemDate.getMonth() === newDate.getMonth();
  const sameDay = itemDate.getDate() === newDate.getDate();

  return sameYear && sameMonth && sameDay;
};

const Emojis = {
  great: "😄",
  good: "😊",
  ok: "😐",
  "could be better": "😕",
  bad: "😢",
};

const CustomToolbar = (toolbar) => {
  // Your custom header component here
  return (
    <div className="t-flex t-items-center t-gap-4 t-p-2">
      <span className="t-text-lg t-md:text-xl !t-font-[700] t-text-secondary">
        {toolbar.label}
      </span>
      <Icon
        name="chevronLeft"
        color="#005d67"
        onClick={() => toolbar.onNavigate("PREV")}
      />
      <Icon
        name="chevronRight"
        color="#005d67"
        onClick={() => toolbar.onNavigate("NEXT")}
      />
    </div>
  );
};

const CustomerCheckIn = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const token = localStorage.getItem("customer_token");
  const { user } = useSelector((state) => state.user);

  const [showFeelingsModal, setShowFeelingsModal] = useState(false);
  const [feeling, setFeeling] = useState("good");
  const [message, setMessage] = useState("");

  const [checkIn, { loading }] = useCustomerCheckInMutation();

  const isUserCheckedInToday = useMemo(
    () => user?.checkIns?.find((item) => isSameDate(item.date, new Date())),
    [user]
  );

  const events = useMemo(
    () =>
      user?.checkIns?.map((item) => {
        return {
          title: (
            <p className="t-text-[10px] t-py-[2px] t-capitalize t-m-0">
              {item.feeling} {Emojis[item.feeling] ?? "😀"}
            </p>
          ),
          start: new Date(item.date),
          end: new Date(item.date),
          allDay: false,
          description: item?.message,
        };
      }),
    [user]
  );

  const onSubmit = async () => {
    try {
      const data = await checkIn({
        checkIns: {
          date: moment(),
          feeling,
          message,
        },
      }).unwrap();
      dispatch(getProfileActions(token));
      setShowFeelingsModal(false);
    } catch (err) {
      toast.error(err?.data?.message || "Something went wrong");
    }
  };

  const getEventStyle = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: isSelected ? "#39beae" : "#005d67",
        borderRadius: "5px",
        color: "#fff", // Text color
        border: "1px solid #005d67", // Border color
      },
    };
  };

  const isToday = (date) => {
    const today = moment().startOf("day");
    return moment(date).isSame(today, "day");
  };

  const getDayStyle = (date) => {
    if (isToday(date)) {
      return {
        style: {
          backgroundColor: "#f9f9fc",
        },
      };
    }
    return {};
  };

  const [selectedEvent, setSelectedEvent] = useState(null);
  const handleSelectEvent = (event, e) => {
    e.preventDefault();
    setSelectedEvent(event);
  };

  return (
    <DashboardWrapper title={t(CheckIn)}>
      <div className="col-xl-8 col-lg-8 user-inner">
        <div className="profile-reviews-wrapper">
          <div className="welcome">
            <h4 className="t-font-bold">Daily Check Ins</h4>
          </div>
        </div>
        <div className="t-flex t-flex-col t-w-[150px] t-items-start t-gap-1 t-bg-gray-200 t-shadow-sm t-p-2 t-rounded-sm">
          <p className="t-m-0 t-text-xs">{t(TotalCheckIns)}</p>
          <h4 className="t-m-0 t-text-tertiary t-font-bold">
            {user?.totalCheckIns}
          </h4>
        </div>
        {isUserCheckedInToday ? (
          <div className="t-relative">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              className="!t-h-[400px] md:!t-h-[500px] t-mt-5"
              eventPropGetter={getEventStyle}
              dayPropGetter={getDayStyle}
              onSelectEvent={handleSelectEvent}
              components={{
                toolbar: CustomToolbar,
              }}
            />
            {selectedEvent && selectedEvent?.description && (
              <div className="t-bg-secondary t-text-white t-p-2 t-rounded-sm t-m-0 t-mt-2">
                <p className="t-m-0">{selectedEvent.description}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="t-relative t-flex t-flex-col t-items-center t-justify-between t-gap-4">
            <div className="t-flex t-flex-col i-items-center t-justify-center t-gap-4">
              <Icon
                name="plus"
                className="t-h-24 t-w-24 t-flex t-items-center t-justify-center t-p-4 t-mx-auto t-rounded-full t-bg-gray-200 t-mt-8 md:t-mt-16"
                onClick={() => setShowFeelingsModal(true)}
                size="40"
                color="#005d67"
              />
              <p className="t-text-base t-font-bold">
                Check in today to see your calendar
              </p>
            </div>
            {showFeelingsModal && (
              <div className="t-absolute t-h-auto t-w-64 t-flex t-flex-col t-gap-2 t-rounded-lg t-bg-gray-100 t-p-4">
                <p className="t-text-base t-text-center t-font-[600]">
                  {t(FeelingText)}
                </p>
                <div className="t-flex t-flex-row t-items-center t-justify-center t-gap-4">
                  {Object.keys(Emojis).map((item) => (
                    <p
                      className={`t-cursor-pointer ${
                        feeling === item ? "t-text-5xl" : "t-text-2xl"
                      }`}
                      onClick={() => setFeeling(item)}
                      key={item}
                    >
                      {Emojis[item]}
                    </p>
                  ))}
                </div>
                <textarea
                  rows={3}
                  className="form-control !t-rounded-lg !t-p-2 t-mb-2"
                  placeholder="Message (optional)"
                  defaultValue={""}
                  value={message}
                  maxLength={500}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <div className="t-flex t-flex-col t-items-center t-gap-2">
                  <button
                    href="#"
                    className="btn btn-custom-secondary fw-bold lts1 px-5"
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    {t(Submit)}
                  </button>
                  <button
                    className="btn btn-custom-secondary fw-bold lts1 px-5"
                    onClick={() => setShowFeelingsModal(false)}
                    disabled={loading}
                  >
                    {t(Cancel)}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </DashboardWrapper>
  );
};

export default CustomerCheckIn;
