import { Icon } from "./icon";

const Loader = () => (
  <div
    className="t-absolute t-top-0 t-w-[100vw] t-h-[100vh] t-flex t-items-center t-justify-center t-z-50 t-overflow-hidden"
    style={{ background: "rgba(0,0,0,0.5)" }}
  >
    <Icon name="spinner" />
  </div>
);

export default Loader;
