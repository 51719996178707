import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getUserShiipingInfo } from "../../app/api/useDataManage/userActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function CustomerAddress() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [token] = useState(localStorage.getItem("customer_token"));
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    address: "",
    country: "",
    appartment: "",
    state: "",
    zipCode: "",
  });

  const getShippingAddressUser = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}customer/getShippingAddress`,
        { email: user.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInputs({
        address: res?.data?.shippingAddress?.address,
        country: res?.data?.shippingAddress?.country,
        appartment: res?.data?.shippingAddress?.appartment,
        state: res?.data?.shippingAddress?.state,
        zipCode: res?.data?.shippingAddress?.zipCode,
      });
    } catch (err) {
      console.log("Error -> ", err);
    }
  };

  useEffect(() => {
    getShippingAddressUser();
    dispatch(getUserShiipingInfo({ email: user.email }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token, user.email]);

  useEffect(() => {
    async function getCountries() {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const countriesData = await response.json();

        const countries = countriesData.map((country) => ({
          value: country.cca2.toLowerCase(),
          label: country.name.common,
        }));

        setCountries(countries);
      } catch (error) {
        console.error("Error fetching countries:", error.message);
      }
    }
    getCountries();
  }, []);

  const handleCountryChange = (selectedOption) => {
    let target = { name: "country", value: selectedOption.value };
    handleInputChange({ target });
  };
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const updateData = { ...inputs, email: user.email };
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}customer/updateShippingAdrees`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInputs({
        address: res?.data?.shippingAddress?.address,
        country: res?.data?.shippingAddress?.country,
        appartment: res?.data?.shippingAddress?.appartment,
        state: res?.data?.shippingAddress?.state,
        zipCode: res?.data?.shippingAddress?.zipCode,
      });
      toast.success("Address updated successfully");
    } catch (err) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "23px",
      height: "40px", // Adjust the height as needed
    }),
  };

  return (
    <div className="container">
      <div className="welcome mb-4">
        <h4 className="t-font-bold">Address</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row gy-4">
          <div className="col-lg-12 position-relative">
            <label className="t-text-gray-600 !t-ml-0">Address</label>
            <input
              type="text"
              className="form-control t-h-[40px] !t-pl-3"
              name="address"
              id="address"
              placeholder="Enter your address"
              value={inputs.address}
              onChange={handleInputChange}
              required
              minLength={5}
            />
          </div>
          <div className="col-lg-6 position-relative">
            <label className="t-text-gray-600 !t-ml-0">Country</label>

            <Select
              options={countries}
              value={countries.find(
                (c) => c.value === inputs.country?.toLowerCase()
              )}
              onChange={handleCountryChange}
              styles={customStyles}
            />
          </div>
          <div className="col-lg-6 position-relative">
            <label className="t-text-gray-600 !t-ml-0 ">Appartment</label>
            <input
              type="text"
              className="form-control t-h-[40px] !t-pl-3"
              placeholder="Enter apartment"
              name="appartment"
              id="appartment"
              value={inputs.appartment}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-6 position-relative">
            <label className="t-text-gray-600 !t-ml-0 ">State</label>
            <input
              type="text"
              className="form-control t-h-[40px] !t-pl-3"
              name="state"
              id="state"
              placeholder="Enter state"
              value={inputs.state}
              onChange={handleInputChange}
              required
              minLength={3}
            />
          </div>
          <div className="col-lg-6 position-relative">
            <label className="t-text-gray-600 !t-ml-0">Zip Code</label>
            <input
              type="text"
              className="form-control t-h-[40px] !t-pl-3"
              name="zipCode"
              id="zipCode"
              placeholder="Enter zip code"
              value={inputs.zipCode}
              onChange={handleInputChange}
              required
              minLength={3}
            />
          </div>
        </div>
        <div className="t-mt-6">
          <button
            type="sumit"
            className="btn btn-custom-secondary fw-bold lts1 px-5"
            disabled={loading}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default CustomerAddress;
