import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const login = useState(JSON.parse(localStorage.getItem("login1")))[0];
  const [authenticated] = useState(false);
  return login || authenticated ? <Outlet /> : <Navigate exact to={`/login`} />;
};

export default PrivateRoute;
