import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../features/langSlice";
import { useGetContentsByTagWebQuery } from "../features/contentsApiSlice";
import Testimonials from "./Home/Slider/Testimonials";
import CustomLink from "./CustomLink";

const Partner = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();

  const [data, setData] = useState([]);
  const [skip] = useState(false);

  const [picture1, setPicture1] = useState("");
  const [picture2, setPicture2] = useState("");
  const [picture3, setPicture3] = useState("");

  const {
    data: fetchContents,
    isSuccess,
    refetch,
  } = useGetContentsByTagWebQuery({ tag: "partner", lang: lang, skip: skip });

  const { data: homepageContent } = useGetContentsByTagWebQuery({
    tag: "home",
    lang: lang,
    skip: skip,
  });

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchContents.data);

      if (fetchContents.data[0]) {
        setPicture1(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[3].picture}`
        );
        setPicture2(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[4].picture}`
        );
        setPicture3(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[5].picture}`
        );
      }
    }
  }, [fetchContents, isSuccess]);

  return (
    <Fragment>
      <section className="">
        <div className="t-pt-6">
          <div className="container">
            <div className="col-lg-7 mx-auto text-center px-3 t-min-h-[456px] md:t-min-h-[360px]">
              <h4 className="mb-4 top-head">
                {data[0] && (
                  <span
                    className="fw-bold"
                    dangerouslySetInnerHTML={{ __html: data[0].title }}
                  />
                )}
              </h4>
              {data[0] && (
                <h5
                  className="lh-base"
                  dangerouslySetInnerHTML={{ __html: data[0].contents }}
                />
              )}

              <div className="text-center mt-4">
                {data[1] && (
                  <CustomLink
                    to={
                      "https://calendly.com/alessandro-shift-mgmt/riise-with-ale"
                    }
                    className="btn btn-custom-secondary fw-bold lts1 px-3"
                  >
                    <span
                      className="fw-bold"
                      dangerouslySetInnerHTML={{ __html: data[1].title }}
                    />
                  </CustomLink>
                )}
              </div>
              {data[2] && (
                <h5
                  className="lh-base mt-3"
                  dangerouslySetInnerHTML={{ __html: data[2].contents }}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="difference-section section-padding ">
        <div className="container t-min-h-[1900px] md:t-min-h-[1180px]">
          <div className="col-lg-10 mx-auto">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                {data[3] && (
                  <h4
                    className="lh-base"
                    dangerouslySetInnerHTML={{ __html: data[3].title }}
                  />
                )}

                {data[3] && (
                  <p
                    className="lh-base"
                    dangerouslySetInnerHTML={{ __html: data[3].contents }}
                  />
                )}
              </div>
              <div className="col-lg-6 ">
                <img src={picture1} alt="" className="img-fluid partner-img" />
              </div>
            </div>

            <div className="row mtrow">
              <div className="col-lg-6 float-start">
                <img src={picture2} alt="" className="img-fluid partner-img" />
              </div>

              <div className="col-lg-6 d-flex flex-column justify-content-center">
                {data[4] && (
                  <h4
                    className="lh-base"
                    dangerouslySetInnerHTML={{ __html: data[4].title }}
                  />
                )}

                {data[4] && (
                  <p
                    className="lh-base"
                    dangerouslySetInnerHTML={{ __html: data[4].contents }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials section-padding !t-pb-10 !t-pt-10 t-min-h-[315px] md:t-min-h-[400px]">
        <div className="container">
          <div className="row align-items-center title-wrapper !t-mb-0">
            <div className="col-lg-12 !t-mb-0">
              <div className="t-items-center !t-mb-0">
                <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary t-mb-0">
                  {data[2] && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: homepageContent?.data[2]?.title,
                      }}
                    />
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Testimonials />
            </div>
          </div>
        </div>
      </section>

      <section
        className="breadcrumbs-wrapper-partner t-min-h-[208px]"
        style={{
          backgroundImage: `linear-gradient(var(--secondary-80),var(--secondary-80)),  url(${picture3})`,
        }}
      >
        <div className="container">
          <div className="col-lg-7 mx-auto text-center px-3">
            <h4 className="mb-4 top-head">
              {data[5] && (
                <span
                  className="fw-bold"
                  dangerouslySetInnerHTML={{ __html: data[5].title }}
                />
              )}
            </h4>
            {data[5] && (
              <h5
                className="lh-base"
                dangerouslySetInnerHTML={{ __html: data[5].contents }}
              />
            )}
            <div className="text-center mt-4">
              <CustomLink
                to={"https://calendly.com/alessandro-shift-mgmt/riise-with-ale"}
                className="btn btn-custom-secondary fw-bold lts1 px-3"
              >
                {data[6] && (
                  <span dangerouslySetInnerHTML={{ __html: data[6].title }} />
                )}
              </CustomLink>
            </div>
            {data[7] && (
              <h5
                className="lh-base mt-3"
                dangerouslySetInnerHTML={{ __html: data[7].contents }}
              />
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Partner;
