import React from "react";
import BannerImg from "../../../assets/focusareas/energy-banner.webp";
import { Link } from "react-router-dom";
import { TakeTheTestButton } from "../../../Constants";
import { useTranslation } from "react-i18next";
import CustomLink from "../../CustomLink";

const Banner = ({ heading1, heading2, desc1, desc2 }) => {
  const { t } = useTranslation();
  return (
    <div className="t-flex t-items-center t-justify-center t-gap-4 t-max-w-[1330px] !t-mx-auto t-py-16 t-px-8">
      <div className="t-w-full t-flex t-flex-col t-items-start t-gap-4">
        <div>
          <h1 className="t-m-0">{heading1}</h1>
          <h1
            className="t-font-extrabold t-text-left t-m-0"
            style={{ fontFamily: "Roboto" }}
          >
            {heading2}
          </h1>
        </div>
        <div className="t-flex t-flex-col t-gap-4 t-w-[50%]">
          <p className="t-m-0 t-leading-4">{desc1}</p>
          <p className="t-m-0 t-leading-4">{desc2}</p>
          <CustomLink to="/quiz">
            <span className="btn btn-custom-secondary !t-rounded-lg">
              {t(TakeTheTestButton)}
            </span>
          </CustomLink>
        </div>
      </div>
      <img src={BannerImg} alt="" className="t-w-[30%] t-h-[30]" />
    </div>
  );
};

export default Banner;
