import React from "react";
import Tick from "../../assets/images/home/tick.png";
import { useTranslation } from "react-i18next";
import {
  EachActive,
  HighStandardDesc1,
  HighStandardDesc2,
  HighStandardDesc3,
  HighStandardHeading,
} from "../../Constants";
import { Link } from "react-router-dom";
import CustomLink from "../CustomLink";

const HighStandards = () => {
  const { t } = useTranslation();
  return (
    <div className="t-w-full t-flex t-flex-col t-items-center t-gap-4 t-p-4">
      <h2 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
        {t(HighStandardHeading)}
      </h2>
      <div className="t-flex t-flex-col t-items-start t-ml-28 sm:t-ml-32 t-gap-4 t-w-full t-max-w-[300px] !t-mx-auto">
        <div className="t-flex t-items-center sm:t-justify-center t-gap-2">
          <img src={Tick} alt="" className="t-h-6 t-w-6 t-rounded-full" />
          <p className="t-m-0 t-text-lg t-text-[#29a999]">
            {t(HighStandardDesc1)}
          </p>
        </div>
        <div className="t-flex t-items-center t-gap-2">
          <img src={Tick} alt="" className="t-h-6 t-w-6 t-rounded-full" />
          <p className="t-m-0 t-text-lg t-text-[#29a999]">
            {t(HighStandardDesc2)}
          </p>
        </div>
        <div className="t-flex t-items-center t-gap-2">
          <img src={Tick} alt="" className="t-h-6 t-w-6 t-rounded-full" />
          <p className="t-m-0 t-text-lg t-text-[#29a999]">
            {t(HighStandardDesc3)}{" "}
            <span>
              <CustomLink to="/ingredients">&nbsp;{t(EachActive)}</CustomLink>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HighStandards;
