import React from "react";
import { useTranslation } from "react-i18next";
import {
  MakeChoiceHeading,
  MakeChoiceFeature,
  TableRow1,
  TableRow2,
  TableRow3,
  TableRow4,
  TableRow5,
  TableRow6,
  TableRow7,
} from "../../Constants";
import { Icon } from "../elements/icon";
import { logo_home } from "../../Data/Images/Home";
import RiisePouch from "../../assets/yellow-paper-pouch-granules.webp";

const MakeChoice = ({ isProductPage }) => {
  const { t } = useTranslation();

  const DATA = [
    {
      feature: t(TableRow1),
      bioniq: true,
      others: false,
    },
    {
      feature: t(TableRow2),
      bioniq: true,
      others: false,
    },
    {
      feature: t(TableRow3),
      bioniq: true,
      others: false,
    },
    {
      feature: t(TableRow4),
      bioniq: true,
      others: false,
    },
    {
      feature: t(TableRow5),
      bioniq: true,
      others: false,
    },
    {
      feature: t(TableRow6),
      bioniq: true,
      others: false,
    },
    {
      feature: t(TableRow7),
      bioniq: true,
      others: false,
    },
  ];
  return (
    <div className="t-flex t-flex-col t-gap-4 t-py-4">
      <h2
        className={`${
          isProductPage
            ? "t-text-black t-text-start"
            : "t-text-secondary t-text-center"
        } t-text-[24px] md:t-text-[30px]`}
      >
        {t(MakeChoiceHeading)}
      </h2>
      <div className="t-overflow-x-auto t-p-4">
        <table className="t-border-collapse t-w-full t-bg-white">
          <thead>
            <tr className="t-border-b">
              <th className="t-border-0 t-p-4 t-text-left t-font-bold t-text-tertiary md:t-text-2xl">
                {t(MakeChoiceFeature)}
              </th>
              <th className="t-bg-gray-100 t-p-4 t-text-center t-font-bold t-text-tertiary t-flex t-gap-1 t-items-center">
                <img src={logo_home} alt="" height={24} width={32} />
                <img src={RiisePouch} alt="" height={24} width={12} />
              </th>
              <th className=" t-border-r-0 t-p-4 t-text-center t-font-bold t-text-tertiary md:t-text-2xl">
                Others
              </th>
            </tr>
          </thead>
          <tbody>
            {DATA.map((row, index) => (
              <tr key={index} className="t-border-b">
                <td className="t-p-4 t-text-secondary t-font-medium md:t-text-xl">
                  {row.feature}
                </td>
                <td className="t-bg-gray-100 t-p-4 t-text-center">
                  <Icon name="tick" color="#005d67" />
                </td>
                <td className="t-p-4 t-text-center">
                  <Icon name="cross" color="#667085" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MakeChoice;
