import { createSlice } from "@reduxjs/toolkit";
import {
  createOrderActions,
  getCustomerReferrerByIdActions,
  getOrderAction,
  getReferreCodeAction,
  getUserDiscountsActions,
} from "./cartActions";
const initialState = {
  cart: null,
  subscription: null,
  message: "",
  orderId: 0,
  discounts: {},
  userOrderCheck: {},
  referrer_codes: null,
  customer_referal_Code: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    getCartItem(state, { payload }) {
      return {
        ...state,
        cart: payload,
      };
    },

    getSubscription(state, { payload }) {
      return {
        ...state,
        subscription: payload,
      };
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(createPayPalActions.fulfilled, (state, { payload }) => {
    //   if (payload !== "") {
    //     state.message = payload;
    //   }
    // });
    builder.addCase(createOrderActions.fulfilled, (state, { payload }) => {
      if (payload.message !== "" && payload.orderId) {
        state.message = payload.message;
        state.orderId = payload.orderId;
      }
    });
    builder.addCase(getUserDiscountsActions.fulfilled, (state, { payload }) => {
      if (payload.discounts) {
        state.discounts = payload.discounts;
      }
    });
    builder.addCase(getOrderAction.fulfilled, (state, { payload }) => {
      state.userOrderCheck = payload;
    });
    builder.addCase(getReferreCodeAction.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.referrer_codes = payload;
      }
    });
    builder.addCase(
      getCustomerReferrerByIdActions.fulfilled,
      (state, { payload }) => {
        state.customer_referal_Code = payload;
      }
    );
  },
});

export const cartActions = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
