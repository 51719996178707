import React, { Fragment, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { logo } from "../Data/Images/Home";
import { useRegisterMutation } from "../features/auth/authApiSlice";
import "../assets/scss/pages/_login.css";
import CustomLink from "../Components/CustomLink";

const SignUps = () => {
  const [register, { isLoading }] = useRegisterMutation();

  const [name, setName] = useState("");

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [cPassword, setCPassword] = useState();
  const history = useNavigate();

  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);

  const [emailErrMsg, setEmailErrMsg] = useState("");

  const signUp = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        await register({
          name: name,
          email: email,
          password: password,
          active: 1,
        }).unwrap();

        clears();

        history(`/signup/complete`, {
          state: "Congratulations! Registration Complete",
        });

        //history(`${process.env.PUBLIC_URL}/dashboard`);
        //toast.success("Registration complete!..");
      } catch (error) {
        if (error.status === 409) {
          setEmailErrMsg(error.data.message);
          setEmailErr(true);
        } else if (error.response) {
          //do something
        } else if (error.request) {
          //do something else
        } else if (error.message) {
          //do something other than the other two
        }
        //toast.error("You enter wrong password or username!..");
      }
    } else {
      //toast.error("You enter wrong password or username!..");
    }
  };

  const validate = () => {
    setNameErr(false);
    setEmailErr(false);
    setPasswordErr(false);
    setConfirmPasswordErr(false);

    setEmailErrMsg("");

    if (name.length < 3) {
      setNameErr(true);
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setEmailErrMsg("Please enter valid Email.");
      setEmailErr(true);
      return false;
    }

    const passReges = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (!passReges.test(password)) {
      setPasswordErr(true);
      return false;
    }

    if (password !== cPassword) {
      setConfirmPasswordErr(true);
      return false;
    }

    //history(`/signup/complete`, {state: 'Congratulations! Registration Complete'})

    return true;
  };

  const clears = () => {
    setName("");
    setEmail("");
    setPassword("");
    setCPassword("");
  };

  return (
    <Fragment>
      <Container fluid={true} className="login-section section-padding pt-0">
        <div className="container d-flex t-flex-col align-items-center justify-content-center vh-100 t-gap-2">
          <div className="mlogo t-mb-5">
            <CustomLink to="/">
              <img src={logo} alt="" width={120} />
            </CustomLink>
          </div>
          <div className="t-w-full t-max-w-[475px] t-mx-auto t-bg-gray-50 t-flex t-flex-col t-gap-12 t-shadow-lg t-rounded-md !t-py-12 t-px-4">
            <div className="t-text-2xl t-font-bold t-text-center t-text-tertiary">
              Create your Riise account
            </div>
            <form action="" className="t-flex t-flex-col t-gap-8">
              <div className="row gy-1 textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-describedby="helpId"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  <i className="fa far fa-user-circle" />
                </div>
                {nameErr ? (
                  <span className="d-block t-text-red-500 w-100 t-text-start">
                    Name should be 3 characters long.
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="row gy-1 textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    aria-describedby="helpId"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <i className="fa fas fa-envelope" />
                </div>
                {emailErr ? (
                  <span className="d-block t-text-red-500 w-100 t-text-start">
                    {emailErrMsg}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="row gy-1 textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    aria-describedby="helpId"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <i className="fa fas fa-key" />
                </div>
                {passwordErr ? (
                  <span className="d-block t-text-red-500 w-100 t-text-start">
                    Password should contains a lowercase, a uppercase character
                    and a digit and at least 8 characters.
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="row gy-1 textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    aria-describedby="helpId"
                    onChange={(e) => setCPassword(e.target.value)}
                    value={cPassword}
                  />
                  <i className="fa fas fa-key" />
                </div>
                {confirmPasswordErr ? (
                  <span className="d-block t-text-red-500 w-100 t-text-start">
                    Passwords should be match.
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    href="#"
                    className="col-12 btn btn-custom-secondary fw-bold lts1 px-5"
                    onClick={(e) => signUp(e)}
                    disabled={isLoading}
                  >
                    Register Here
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <CustomLink to={`${process.env.PUBLIC_URL}/login`}>
                    <p className="t-text-tertiary">
                      Do you already have an account? Click here to login
                    </p>
                  </CustomLink>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default SignUps;
