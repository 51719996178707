import React from "react";
import { Link, useLocation } from "react-router-dom";

const CustomLink = ({ to, className, onClick, style, children }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get("lang") || "en";
  const separator = to.includes("?") ? "&" : "?";
  const finalUrl = `${to}${separator}lang=${lang}`;

  return (
    <Link to={finalUrl} className={className} onClick={onClick} style={style}>
      {children}
    </Link>
  );
};

export default CustomLink;
