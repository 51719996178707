import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = `${process.env.REACT_APP_BASE_URL}customer`;

export const getProfileActions = createAsyncThunk(
  "user/getPrfile",
  async (token) => {
    try {
      const { data } = await axios.get(
        `${url}/getProfile`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const updateUserProfieAction = createAsyncThunk(
  "user/updateProfile",
  async (userData) => {
    try {
      const { data } = await axios.put(
        `${url}/updateCustomerProfile`,
        userData?.user,
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      return data;
    } catch (error) {}
  }
);

export const updatePasswordActions = createAsyncThunk(
  "user/updatePass",
  async (passData) => {
    try {
      const { data } = await axios.put(`${url}/updatePass`, passData?.data, {
        headers: {
          Authorization: `Bearer ${passData?.token}`,
        },
      });
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getAddressAction = createAsyncThunk(
  "customer/getAddress",
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/address/get`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      let message = error.response.data.message;

      return { message };
    }
  }
);

export const createAddressAction = createAsyncThunk(
  "customer/createAdress",
  async (addressData) => {
    try {
      const { data } = await axios.post(`${url}/address`, addressData.data, {
        headers: { Authorization: `Bearer ${addressData.token}` },
      });
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const updateAddressAction = createAsyncThunk(
  "customer/update",
  async (addressData) => {
    try {
      const { data } = await axios.put(`${url}/address`, addressData.data, {
        headers: { Authorization: `Bearer ${addressData.token}` },
      });

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const createCustomerProfileActions = createAsyncThunk(
  "customer/create",
  async (profileData) => {
    try {
      const { data } = await axios.post(
        `${url}/createProfile`,
        profileData.user,
        {
          headers: { Authorization: `Bearer ${profileData.token}` },
        }
      );
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getCustomerProfileAction = createAsyncThunk(
  "customer/get",
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/getCustomerProfile`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getCustomerProfileFirstAction = createAsyncThunk(
  "customer/getprofile",
  async (userData) => {
    try {
      const { data } = await axios.post(
        `${url}/getCustomerProfileFirst`,
        userData
      );
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getUserSubscriptionActions = createAsyncThunk(
  "customer/subscription",
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/getUserSubscription`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {}
  }
);

export const getUsersOrderActions = createAsyncThunk(
  "customer/orders",
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/getOrders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {}
  }
);

export const customerQuizDetailsAction = createAsyncThunk(
  "customer/quizzDetails",
  async (reqData) => {
    try {
      const { data } = await axios.post(`${url}/quizdetail`, reqData.quizData, {
        headers: {
          Authorization: `Bearer ${reqData.token}`,
        },
      });
      return data.quizDetails;
    } catch (error) {}
  }
);

export const lastQuizDetailsAction = createAsyncThunk(
  "customer/lastquizzDetails",
  async (reqData) => {
    try {
      const { data } = await axios.post(
        `${url}/lastquizdetail`,
        { lang: reqData.lang },
        {
          headers: {
            Authorization: `Bearer ${reqData.token}`,
          },
        }
      );
      return data.quizDetails;
    } catch (error) {}
  }
);

export const getOrderDetails = createAsyncThunk(
  "order/orderDetail",
  async (orderData) => {
    try {
      const id = orderData?.id;
      const { data } = await axios.post(`${url}/orderDetail/${id}`);

      return data?.orderDetails;
    } catch (error) {}
  }
);

export const getPaymentGateWayInfo = createAsyncThunk(
  "payemnt/orderInfo",
  async () => {
    try {
      const { data } = await axios.get(`${url}/getGateWays`);
      return data.gatwayInfo;
    } catch (error) {
      console.log("error during getting gateways", error.message);
    }
  }
);

export const getUserShiipingInfo = createAsyncThunk(
  "user/shipping",
  async (emailData) => {
    try {
      const { data } = await axios.post(
        `${url}/getUserShiipingInfo`,
        emailData
      );
      return data.shippingAddress;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getShippingAddress = createAsyncThunk(
  "user/shippingAddress",
  async (emailData) => {
    try {
      const token = localStorage.getItem("customer_token");

      const { data } = await axios.post(
        `${url}/getShippingAddress`,
        emailData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.shippingAddress;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const updateShippingAddress = createAsyncThunk(
  "customer/updateShippingAddress",
  async (addressData) => {
    try {
      const { data } = await axios.put(
        `${url}/updateShippingAdrees`,
        addressData.data,
        {
          headers: { Authorization: `Bearer ${addressData.token}` },
        }
      );

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const createNewUserPassAction = createAsyncThunk(
  "user/pass",
  async (userData) => {
    try {
      const { data } = await axios.put(
        `${url}/createNewUserPass`,
        { password: userData.password },
        {
          headers: { Authorization: `Bearer ${userData.token}` },
        }
      );
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const logOutUserAction = createAsyncThunk(
  "user/logout",
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/logout`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");
      const i18nextLng = localStorage.getItem("i18nextLng");
      localStorage.clear();
      localStorage.setItem("cookiesAccepted", cookiesAccepted);
      localStorage.setItem("i18nextLng", i18nextLng);

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);
