import React from "react";
import CustomLink from "./CustomLink";

const NotFound = () => {
  return (
    <div className="t-h-[80vh] t-w-screen t-flex t-flex-col t-gap-2 t-items-center t-justify-center t-p-4">
      <h3 className="t-text-center">
        Uh-oh: looks like the page you're looking for doesn't exist!
      </h3>
      <CustomLink to="/" className="btn btn-custom-secondary !t-rounded-lg">
        Go to the homepage
      </CustomLink>
    </div>
  );
};

export default NotFound;
