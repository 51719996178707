import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { useLocation } from "react-router-dom";

import { logo } from "../Data/Images/Home";
import { ToastContainer } from "react-toastify";

import "../assets/scss/pages/_login.css";
import CustomLink from "../Components/CustomLink";

const SignUpComplete = () => {
  const { state } = useLocation();
  return (
    <Fragment>
      <Container fluid={true} className="login-section section-padding pt-0">
        <div className="container d-flex align-items-center justify-content-center vh-100">
          <div className="col-6">
            <div className="mlogo mb-5">
              <CustomLink to="">
                <img src={logo} alt="" width={120} />
              </CustomLink>
            </div>
            <h3 className="text-center text-white mb-5">{state}</h3>

            <div className="row mt-5">
              <div className="col-6">
                <CustomLink to={`${process.env.PUBLIC_URL}/login`}>
                  Go to Login
                </CustomLink>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default SignUpComplete;
