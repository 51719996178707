import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "../../elements/icon";
import CustomLink from "../../CustomLink";

const DashboardMenuItem = ({ href, icon, name, setIsMenuOpen }) => {
  const location = useLocation();

  const active = useMemo(
    () => location && location.pathname === href,
    [href, location]
  );

  return (
    <CustomLink
      to={href}
      className={`t-group t-flex !t-items-center t-gap-4 t-px-4 t-py-2 hover:t-bg-gray-100 ${
        active ? "t-bg-gray-100" : ""
      }`}
      onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
    >
      <Icon
        name={icon}
        color={active ? "#003b4d" : "#475467"}
        size="24"
        className=""
      />
      <p
        className={`t-text-sm md:t-text-base !t-font-bold t-m-0 ${
          active ? "t-text-tertiary" : "t-text-gray-600"
        }`}
      >
        {name}
      </p>
    </CustomLink>
  );
};

export default DashboardMenuItem;
