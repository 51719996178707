// PaymentForm.jsx
import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useSelector } from "react-redux";
const PaymentForm = ({ onDataEntered, price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useSelector((state) => state.user);
  const [product] = useState(JSON.parse(localStorage.getItem("product")));
  const [subscription] = useState(
    JSON.parse(localStorage.getItem("subscription"))
  );
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    try {
      if (subscription.duration === "1 time") {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}stripePayment`,
          { total: price, subscriptionDuration: subscription.duration }
        );

        const { clientSecret } = data;

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          }
        );

        if (error) {
          onDataEntered({
            error: error.message,
          });
          return;
        } else {
          onDataEntered({
            transactionId: paymentIntent.id,
            subscriptionId: "",
            customerId: "",
            paymentJson: data.json(),
          });
          return;
        }
      } else {
        // Create a payment method
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

        // Send payment method to your server for subscription creation
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}stripePayment`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: `${user?.email}`, // Replace with the user's email
              payment_method: paymentMethod.id,
              total: price,
              productName: product.name,
              subscriptionDuration: subscription.duration,
            }),
          }
        );

        if (response.ok) {
          // Handle success (e.g., show a success message)
          const data = await response.json();
          let { paymentIntent, error } = await stripe.confirmCardPayment(
            data?.clientSecret,
            {
              payment_method: {
                card: elements.getElement(CardElement),
                // billing_details: {
                //   name: "name",
                // },
              },
            }
          );

          if (error) {
            onDataEntered({
              error: error.message,
            });
            // show error and collect new card details.
            return;
          } else {
            onDataEntered({
              transactionId: paymentIntent.id,
              subscriptionId: data?.subscriptionId,
              customerId: data?.customerId,
              paymentJson: data,
            });
            return;
          }
        } else {
          // Handle error (e.g., show an error message)
          onDataEntered({
            error: "subscription failed",
          });
        }
      }
    } catch (error) {
      onDataEntered({
        error: error.message,
      });
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        justifyContent: "space-between",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardElement />
      <input
        type="submit"
        value={"Pay Now"}
        style={{
          margin: "30px 30px 0px 30px",
          alignItems: "flex-end",
          justifyContent: "center",
          border: "none",
        }}
        className="btn-custom "
      />
    </form>
  );
};

export default PaymentForm;

// // PaymentForm.jsx
// import React, { useImperativeHandle, useState } from "react";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import axios from "axios";
//
// const PaymentForm = ({ onDataEntered, price }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [paymentError, setPaymentError] = useState(null);
//   const [product, setProduct] = useState(
//     JSON.parse(localStorage.getItem("product"))
//   );
//   const [token, setToken] = useState(localStorage.getItem("customer_token"));
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!stripe || !elements) {
//       return;
//     }
//     try {
//       const { data } = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}stripePayment`,
//         { total: price }
//       );
//
//       const { clientSecret } = data;
//
//
//       const { paymentIntent, error } = await stripe.confirmCardPayment(
//         clientSecret,
//         {
//           payment_method: {
//             card: elements.getElement(CardElement),
//           },
//         }
//       );
//
//       if (error) {
//         onDataEntered({
//           error: error.message,
//         });
//       } else {
//         onDataEntered({
//           transactionId: paymentIntent.id,
//         });
//       }
//     } catch (err) {
//       onDataEntered({
//         error: err.message,
//       });
//     }
//   };
//
//   // Return the CardElement component for the form
//   return (
//     <form
//       onSubmit={handleSubmit}
//       style={{
//         justifyContent: "space-between",
//         flex: 1,
//         display: "flex",
//         flexDirection: "column",
//       }}
//     >
//       <CardElement />
//       <input
//         type="submit"
//         value={"Pay Now"}
//         style={{
//           margin: "30px 30px 0px 30px",
//           alignItems: "flex-end",
//           justifyContent: "center",
//           border: "none",
//         }}
//         className="btn-custom "
//       />
//     </form>
//   );
// };
//
// export default PaymentForm;
