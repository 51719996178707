import React, { useEffect, useState } from "react";
import { Subscription } from "../../Constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import axios from "axios";
import { toast } from "react-toastify";
import DashboardWrapper from "./components/DashboardWrapper";
import { Modal } from "../elements/Modal";
import SubscriptionCard from "./components/SubscriptionCard";
import { useGetUserSubscriptionQuery } from "../../features/customerApiSlice";
import { getSubsActions } from "../../app/api/useDataManage/subscriptionApis";
import useProduct from "../../hooks/useProduct";

const url = `${process.env.REACT_APP_BASE_URL}customer`;

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function CustomerSubscriptions() {
  const dispatch = useDispatch();
  const selectedLang = useSelector(selectCurrentLanguage);
  const { fetchedProduct, subs } = useSelector((state) => state.subs);
  const [lang, setLang] = useState();
  const { t } = useTranslation();
  //Todo: update
  const [loading, setLoading] = useState(false);
  const [showCancelAndPause, setShowCancelAndPause] = useState(false);
  const [cancelDate, setCancelDate] = useState(null);
  const [showUpgrade, setShowUpgrade] = useState(false);
  // const [subPaused, setSubPaused] = useState(false);
  // const [subCancelled, setSubCancelled] = useState(false);
  const [upgradeOptions, setUpgradeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { newSubs, setNewSubs, ONE_OFF_SUB } = useProduct();
  // {
  //   id: 10,
  //   duration: '1 month',
  //   picture: 'resources/images/boxsubs-k4r0vnkb54-baekf.jpg',
  //   title: 'Riise monthly subscription - billed monthly ',
  //   description: 'shipped and billed every month',
  // },
  // {
  //   id: 8,
  //   duration: '3 months',
  //   picture: 'resources/images/boxsubs-k4r0vnkb54-baekf.jpg',
  //   title: 'Riise 3 months min. commitment - billed monthly ',
  //   description: 'shipped and billed every month',
  // },
  // {
  //   id: 6,
  //   duration: '6 months',
  //   picture: 'resources/images/boxsubs-k4r0vnkb54-baekf.jpg',
  //   title: 'Riise 6 months min. commitment',
  //   description: 'shipped and billed every 3 months',
  // },

  useEffect(() => {
    dispatch(getSubsActions(selectedLang ?? "en"));
  }, [dispatch, selectedLang]);

  useEffect(() => {
    if (subs) {
      const tempSubs = subs.slice(1, subs.length);
      const sub = [ONE_OFF_SUB, ...tempSubs];
      setNewSubs(sub);
    }
  }, [subs, setNewSubs, ONE_OFF_SUB]);

  const { data: userSubs, isLoading } = useGetUserSubscriptionQuery();
  const [token] = useState(localStorage.getItem("customer_token"));
  const [subscribtion, setSubscription] = useState({
    title: "",
    duration: "",
    description: "",
    subId: null,
    expirey: Date,
  });

  useEffect(() => {
    if (token) {
      async function checkPossibilities() {
        try {
          setLoading(true);
          const { data } = await axios.get(
            `${url}/subscriptions/possibilities?lang=${lang}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const { subId, cancel, upgrade } = data;
          if (cancel?.cancelDate) {
            setCancelDate(cancel.cancelDate);
          }
          if (cancel.can) {
            setShowCancelAndPause(true);
          }
          if (subId) {
            setSubscription({
              title: "",
              duration: "",
              description: "",
              subId: subId,
            });
          }
          if (upgrade.can) {
            setUpgradeOptions(upgrade.availableSubscriptions);
            setShowUpgrade(true);
          }

          setLoading(false);
        } catch (err) {
          console.log("Error -> ", err);
        }
      }

      checkPossibilities();
    }
    // dispatch(getProfileActions(token));
  }, [token, lang]);

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);
  }, [selectedLang, lang]);

  async function handleClick(type, subId) {
    try {
      setLoading(true);
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        if (type === "upgrade") {
          const body = {
            subId: subId,
          };
          const { data } = await axios.post(
            `${url}/subscription/upgrade`,
            body,
            config
          );
          const success = data.success;
          if (success) {
            toast.success(
              "Your upgrade was taking into account for next month!"
            );
            await delay(1500);
            window.location.reload();
          } else {
            toast.error(
              "There was an error while trying to upgrade please contact support."
            );
          }
        } else if (type === "cancel") {
          const { data } = await axios.post(
            `${url}/subscription/cancel`,
            {},
            config
          );
          const success = data.success;
          if (success) {
            toast.success(
              "Your cancelation was taking into account. We are sorry to see you leave."
            );
            await delay(1500);
            window.location.reload();
          } else {
            toast.error(
              "There was an error while trying to cancel please contact support."
            );
          }
        } else if (type === "pause") {
          const { data } = await axios.post(
            `${url}/subscription/pause`,
            {},
            config
          );
          const success = data.success;
          if (success) {
            toast.success("Your pause was taking into account for next month!");
            await delay(1500);
            window.location.reload();
          } else {
            toast.error(
              "There was an error while trying to pause please contact support."
            );
            await delay(1500);
            window.location.reload();
          }
        }
      }
    } catch (e) {
      toast.error(e?.message || "Something went wrong!");
    } finally {
      setLoading(false);
      setConfirmationModal(false);
    }
  }

  const onSelectPlan = (selectedPlan, id) => {
    setSubscription({
      title: selectedPlan.title,
      duration: selectedPlan.duration,
      description: selectedPlan.description,
      subId: selectedPlan.id,
    });
  };

  return (
    <DashboardWrapper title={t(Subscription)}>
      <div className="col-xl-8 col-lg-8 user-inner space-y-12">
        <h4 className="t-font-bold">Subscriptions</h4>
        <p className="t-text-base !t-p-0 !t-m-0 !t-mb-4">
          You can save 15% by upgrading to a higher plan! Do you want to
          upgrade? Or are you going on vacation and want to pause your shipments
          for a month? No problem - email us at{" "}
          <span>
            <a href="mailto:hi@weriise.co" className=" ">
              hi@weriise.co
            </a>
          </span>
          , and we will help!
        </p>
        {isLoading ? (
          <div className="profile-reviews-wrapper">
            <div className="loading">
              <h6>Checking your subscription...</h6>
            </div>
          </div>
        ) : (
          <div className="t-flex t-flex-wrap t-items-stretch t-gap-4">
            {newSubs?.length &&
              newSubs?.map((item) => (
                <SubscriptionCard
                  item={item}
                  key={item.duration + "-subscription"}
                  onSelectPlan={onSelectPlan}
                  subscribtion={subscribtion}
                  showSelectBtn={false}
                  currentPlan={
                    userSubs?.subscription[userSubs?.subscription?.length - 1]
                  }
                />
              ))}
          </div>
        )}
        <p className="t-text-base !t-p-0 !t-m-0 !t-mb-4 !t-mt-4">
          You can also cancel your subscription by emailing us at{" "}
          <span>
            <a href="mailto:hi@weriise.co" className=" ">
              hi@weriise.co
            </a>
          </span>
          . Please send us an email 48 hours before the next payment if you want
          to cancel, so we have time to process it before it gets produced &
          shipped to you. You can find information about your next shipments in
          the section « My orders ». Please note that we cannot refund your
          payments if already processed, as each mix is personalized and created
          uniquely to you.
        </p>

        {/* {!loading ? (
          !subscribtion.subId ? (
            <div className="profile-reviews-wrapper">
              <div className="loading">
                <h2>
                  No subscriptions was found for your account.
                  <br /> Please contact support.
                </h2>
              </div>
            </div>
          ) : (
            <>
              {showUpgrade ? (
                <div>
                  <div className="blog-detail-inner mt-3">
                    <div>
                      <div className="t-flex t-flex-wrap t-items-stretch t-gap-4">
                        {upgradeOptions?.slice(0, 3).map((item, key) => (
                          <SubscriptionCard
                            item={item}
                            onSelectPlan={onSelectPlan}
                            subscribtion={subscribtion}
                            id={key}
                            key={"subscription-" + key}
                            showSelectBtn={false}
                          />
                        ))}
                      </div>
                    </div>
                    {/* <div className="row mt-4">
                      <div className="col-md-3 col-sm-12 mb-3">
                        <button
                          className="btn btn-custom-secondary fw-bold lts1 px-5 w-100"
                          onClick={() => {
                            setSelectedType("upgrade");
                            setConfirmationModal(true);
                            //  handleClick('upgrade', subscribtion.subId)
                          }}
                          disabled={loading}
                        >
                          Upgrade
                        </button>
                      </div>
                    </div> */}
        {/* </div>
                </div> */}
        {/* ) : null} */}

        {/* {showCancelAndPause ? ( */}
        {/* <>
                {" "}
                <div className="!t-mt-8">
                  <div className="profile-reviews-wrapper">
                    <div className="welcome">
                      <h4 className="t-font-bold">Pause you subscription</h4>
                    </div>
                  </div>
                  <div className="blog-detail-inner mt-3">
                    <p>
                      {cancelDate && !showCancelAndPause
                        ? "You can cancel from the: " + cancelDate
                        : null}
                    </p>
                    <div className="row">
                      <div className="col-md-3 col-sm-12 mb-3">
                        <button
                          className="btn btn-custom-secondary-pause fw-bold lts1 px-5 w-100"
                          onClick={() => {
                            setSelectedType("pause");
                            setConfirmationModal(true);
                            // handleClick('pause')
                          }}
                          disabled={!showCancelAndPause || loading}
                        >
                          Pause
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="profile-reviews-wrapper !t-mt-8">
                    <div className="welcome">
                      <h4 className="t-font-bold">Cancel your subscription</h4>
                    </div>
                  </div>
                  <div className="blog-detail-inner mt-3">
                    <p>
                      {cancelDate && !showCancelAndPause
                        ? "You can cancel from the: " + cancelDate
                        : null}
                    </p>
                    <div className="row">
                      <div className="col-md-3 col-sm-12 mb-3">
                        <button
                          className="btn btn-custom-secondary-cancel bg-red-400 fw-bold lts1 px-5 w-100"
                          onClick={() => {
                            setSelectedType("cancel");
                            setConfirmationModal(true);
                            // handleClick('cancel')
                          }}
                          disabled={!showCancelAndPause || loading}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </> */}
        {/* ) : null} */}

        {/* {!showCancelAndPause && !showUpgrade ? (
                <>
                  {" "}
                  <div className="profile-reviews-wrapper ">
                    <div className="loading">
                      <h2>
                        You can not upgrade nor cancel for now. <br />
                        <br />
                        Please come back at the one month before the end of your
                        commitment to manage your subscription.
                      </h2>
                    </div>
                  </div>
                </>
              ) : null} */}
        {/* </>
          )
        ) : (
          <div className="profile-reviews-wrapper">
            <div className="loading">
              <h6>Checking your subscription...</h6>
            </div>
          </div>
        )} */}
      </div>
      <Modal
        open={confirmationModal}
        handleClose={() => setConfirmationModal(false)}
      >
        <div className="t-p-4 t-flex t-flex-col t-gap-4">
          <p className="t-text-3xl t-font-bold t-text-center">Confirmation</p>
          <p className="t-text-xl t-font-bold">
            Are you sure you want to {selectedType} your subscription ?
          </p>
          <div className="t-flex t-flex-row t-items-center t-gap-4">
            <button
              className="btn btn-custom !t-text-white !t-bg-tertiary fw-bold lts1 px-5 w-100 !t-uppercase"
              onClick={() => handleClick(selectedType)}
              disabled={loading}
            >
              Yes, Sure
            </button>
            <button
              className="btn btn-custom-secondary-cancel bg-red-400 fw-bold lts1 px-5 w-100"
              onClick={() => setConfirmationModal(false)}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </DashboardWrapper>
  );
}

export default CustomerSubscriptions;
