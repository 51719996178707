import React from "react";
import { useTranslation } from "react-i18next";
import {
  BestEnjoy1,
  BestEnjoy2,
  BestEnjoy3,
  BestEnjoyDesc,
  BestEnjoyHeading,
} from "../../../Constants";
import EnjoyRiseImg1 from "../../../assets/enjoy-riise-1.webp";
import EnjoyRiseImg2 from "../../../assets/enjoy-riise-2.webp";
import EnjoyRiseImg3 from "../../../assets/enjoy-riise-3.webp";

const HowToEnjoy = () => {
  const { t } = useTranslation();

  const DATA = [
    {
      desc: t(BestEnjoy1),
      img: EnjoyRiseImg1,
    },
    {
      desc: t(BestEnjoy2),
      img: EnjoyRiseImg2,
    },
    {
      desc: t(BestEnjoy3),
      img: EnjoyRiseImg3,
    },
  ];
  return (
    <div className="t-flex t-flex-col t-gap-4">
      <h2 className="t-text-center md:t-text-left">{t(BestEnjoyHeading)}</h2>
      <p className="t-m-0 t-text-center md:t-text-left">{t(BestEnjoyDesc)}</p>
      <div className="t-flex t-items-stretch t-w-full t-gap-4">
        {DATA.map((item, i) => (
          <div className="t-flex t-flex-col t-items-center t-gap-2">
            <p className="t-m-0 t-h-10 t-w-10 t-bg-secondary t-text-white t-rounded-full t-flex t-items-center t-justify-center">
              {i + 1}
            </p>
            <p className="t-m-0 t-text-center t-text-xs">{item.desc}</p>
            <img src={item.img} alt="" className="t-mt-auto" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToEnjoy;
