import { createSlice } from "@reduxjs/toolkit";
import {
  createAddressAction,
  createCustomerProfileActions,
  customerQuizDetailsAction,
  getAddressAction,
  getCustomerProfileAction,
  getOrderDetails,
  getPaymentGateWayInfo,
  getProfileActions,
  getUserShiipingInfo,
  getUserSubscriptionActions,
  getUsersOrderActions,
  updateAddressAction,
  updateUserProfieAction,
  getCustomerProfileFirstAction,
  createNewUserPassAction,
  updatePasswordActions,
  logOutUserAction,
  getShippingAddress,
  updateShippingAddress,
} from "./userActions";

const initialState = {
  isAuthenticated: false,
  user: {},
  customerProfile: null,
  address: {},
  message: "",
  errorMessage: "",
  userSubs: [],
  userOrders: [],
  quizDetails: [],
  orderDetails: [],
  gatwayInfo: [],
  shippingAddress: {},
  shippingAddressNew: {},
  token: "",
};
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileActions.fulfilled, (state, { payload }) => {
      if (
        payload.user !== undefined &&
        payload.user !== null &&
        Object.keys(payload.user).length !== 0
      ) {
        state.user = payload.user;
        state.isAuthenticated = true;
        state.errorMessage = "";
      } else {
        state.isAuthenticated = false;
      }
    });
    builder.addCase(updateUserProfieAction.fulfilled, (state, { payload }) => {
      let customer_profile = payload?.customerProfile;
      if (customer_profile !== null && customer_profile !== undefined) {
        state.isAuthenticated = true;
        state.customerProfile = payload.customerProfile;
        state.errorMessage = "";
      }
      if (payload?.message) {
        state.errorMessage = payload.message;
      }
    });
    builder.addCase(getAddressAction.fulfilled, (state, { payload }) => {
      if (payload.address !== null || payload.address !== undefined) {
        state.address = payload.address;
        state.errorMessage = "";
      }
      if (payload.message) {
        state.errorMessage = payload.message;
      }
    });
    builder.addCase(createAddressAction.fulfilled, (state, { payload }) => {
      if (payload.address !== null || payload.address !== undefined) {
        state.address = payload;
        state.errorMessage = "";
      }
      if (payload.message) {
        state.errorMessage = payload.message;
      }
    });
    builder.addCase(updateAddressAction.fulfilled, (state, { payload }) => {
      if (payload.address !== null || payload.address !== undefined) {
        state.address = payload.address;
        state.errorMessage = "";
      }
      if (payload.message) {
        state.errorMessage = payload.errorMessage;
      }
    });
    builder.addCase(
      createCustomerProfileActions.fulfilled,
      (state, { payload }) => {
        if (
          payload.customerProfile !== null ||
          payload.customerProfile !== undefined
        ) {
          state.customerProfile = payload.customerProfile;
          state.errorMessage = "";
          return;
        }
        if (payload.message) {
          state.errorMessage = payload.message;
        }
      }
    );
    builder.addCase(
      getCustomerProfileAction.fulfilled,
      (state, { payload }) => {
        if (payload.customerProfile) {
          state.customerProfile = payload.customerProfile;
          state.errorMessage = "";
        }
        if (payload.message) {
          state.errorMessage = payload.message;
        }
      }
    );

    builder.addCase(
      getCustomerProfileFirstAction.fulfilled,
      (state, { payload }) => {
        if (payload.customerProfile) {
          state.customerProfile = payload.customerProfile;
          state.errorMessage = "";
        }
        if (payload.message) {
          state.errorMessage = payload.message;
        }
      }
    );

    builder.addCase(
      getUserSubscriptionActions.fulfilled,
      (state, { payload }) => {
        if (payload?.subscription) {
          state.userSubs = payload.subscription;
        }
      }
    );
    builder.addCase(getUsersOrderActions.fulfilled, (state, { payload }) => {
      if (payload?.orders) {
        state.userOrders = payload.orders;
      }
    });
    builder.addCase(
      customerQuizDetailsAction.fulfilled,
      (state, { payload }) => {
        if (payload?.length !== 0) {
          state.quizDetails = payload;
        }
      }
    );
    builder.addCase(getOrderDetails.fulfilled, (state, { payload }) => {
      state.orderDetails = payload;
    });

    builder.addCase(getPaymentGateWayInfo.fulfilled, (state, { payload }) => {
      state.gatwayInfo = payload;
    });
    builder.addCase(getUserShiipingInfo.fulfilled, (state, { payload }) => {
      state.shippingAddress = payload;
    });
    builder.addCase(getShippingAddress.fulfilled, (state, { payload }) => {
      state.shippingAddressNew = payload;
    });
    builder.addCase(updateShippingAddress.fulfilled, (state, { payload }) => {
      if (
        payload.shippingAddress !== null ||
        payload.shippingAddress !== undefined
      ) {
        state.shippingAddressNew = payload.shippingAddress;
        state.errorMessage = "";
      }
      if (payload.message) {
        state.errorMessage = payload.errorMessage;
      }
    });

    builder.addCase(createNewUserPassAction.fulfilled, (state, { payload }) => {
      if (payload?.message) {
        state.errorMessage = payload.message;
      }
    });

    builder.addCase(updatePasswordActions.fulfilled, (state, { payload }) => {
      if (payload?.message) {
        state.message = payload.message;
      }
    });

    builder.addCase(logOutUserAction.fulfilled, (state, { payload }) => {
      if (payload?.message) {
        state.message = payload.message;
      }
    });
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
