import React, { useState } from "react";
import CustomLink from "./CustomLink";
import BannerImage from "../assets/partner/PartnerBanner.webp";
import CopyToClipboard from "../assets/CopyToClipboard.webp";
import UniqueWeb from "./FocusAreas/components/UniqueWeb";
import Unique from "./Home/Unique";
import {
  CustomerReviews,
  MysteryApplyCode,
  MysteryHeading,
  MysterySubHeading,
  RefillBtnText,
} from "../Constants";
import Reviews from "./Customer/components/Reviews";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Icon } from "./elements/icon";
import CountdownTimer from "../Components/CountdownTimer";

const MysteryCredit = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const quizId = searchParams.get("quizId");

  const [copied, setCopied] = useState(false);
  return (
    <>
      <div className="t-bg-primary md:t-bg-white t-w-full t-p-8">
        <div className="t-w-full t-max-w-[1330px] t-mx-auto t-flex t-flex-col t-items-center t-justify-between md:t-flex-row ">
          <div className="t-max-w-[500px] t-h-full t-flex t-flex-col t-gap-2 md:t-gap-4 t-p-2 md:t-p-8">
            <div className="t-flex t-flex-col t-items-center md:t-items-start t-gap-8">
              <h1
                className="t-text-center md:t-text-left  !t-m-0 t-text-black t-text-[30px] t-font-extrabold"
                style={{ fontFamily: "Roboto" }}
              >
                {t(MysteryHeading)}
              </h1>
              <h1 className="t-text-center md:t-text-left !t-m-0 t-text-black t-text-[30px]">
                {t(MysterySubHeading)}
              </h1>
            </div>
            <div className="t-flex t-flex-col t-items-center md:t-items-start t-justify-center md:t-justify-start t-gap-2 md:t-gap-4">
              <p className="!t-m-0 t-text-2xl t-text-center">
                {t(MysteryApplyCode)}
              </p>
              <div
                className="t-w-full t-bg-white md:t-bg-gray-100 t-flex t-items-center t-justify-between t-rounded-lg  t-cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText("96GDC");
                  setCopied(true);
                  setTimeout(() => setCopied(false), 5000);
                }}
              >
                <p
                  className="t-w-[85%] !t-m-0 t-font-extrabold t-text-lg t-text-center t-text-gray-700"
                  style={{ fontFamily: "Roboto" }}
                >
                  96GDC
                </p>
                <div className="t-h-full t-flex t-items-center t-gap-2 t-bg-[#D6D6D6] t-py-2 t-px-4 t-rounded-[0_8px_8px_0]">
                  {copied ? (
                    <Icon
                      name="tick"
                      className="t-bg-green-500 t-rounded-full t-p-[2px]"
                    />
                  ) : (
                    <img src={CopyToClipboard} alt="" className="t-h-6 t-w-6" />
                  )}

                  <p
                    className="!t-m-0 t-text-lg !t-font-bold"
                    style={{ fontFamily: "Roboto" }}
                  >
                    {copied ? "Copied" : "Copy"}
                  </p>
                </div>
              </div>
              <CustomLink
                to={quizId ? `/quiz?quizId=${quizId}` : "/quiz"}
                className="t-w-full t-mt-4"
              >
                <span className="btn btn-custom-tertiary !t-rounded-lg !t-w-full ">
                  {t(RefillBtnText)}
                </span>
              </CustomLink>
              <CountdownTimer />
            </div>
          </div>
          <img
            src={BannerImage}
            alt=""
            className="t-hidden md:t-block md:t-mt-16 t-w-full md:t-w-1/3"
          />
        </div>
      </div>
      <div className="t-hidden md:t-block">
        <UniqueWeb />
      </div>
      <div className="md:t-hidden t-max-w-[100vw] t-flex t-items-center t-justify-center">
        <Unique />
      </div>
      <div className="t-flex t-flex-col t-gap-4 t-overflow-auto t-p-4 t-bg-gray-100">
        <h1 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
          {t(CustomerReviews)}
        </h1>
        <div className="t-w-full t-max-w-[1330px] t-mx-auto">
          <Reviews />
        </div>
      </div>
    </>
  );
};

export default MysteryCredit;
