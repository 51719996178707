import React from "react";

const ImageButton = ({ index, component, onClick }) => {
  const handleClick = (e) => {
    // Call the onClick prop when the button is clicked and pass the component as an argument
    onClick(e, component);
  };

  return (
    <button
      key={index}
      onClick={handleClick}
      className="img-button !t-rounded-lg !t-m-2 !t-text-black hover:!t-border-2"
      style={{
        background: "rgb(242, 244, 247)",
        border: "0px solid #003b4d",
        color: "#000",
      }}
    >
      {component.img ? (
        <img
          src={`${process.env.REACT_APP_PUBLIC_URL}${component.img}`}
          alt=""
        />
      ) : null}
      <span
        className="text !t-text-black"
        dangerouslySetInnerHTML={{ __html: component.label }}
      />
    </button>
  );
};

export default ImageButton;
