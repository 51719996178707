import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logo, logo_home, cart, user } from "../../Data/Images/Home";
import Language from "./Language";
import {
  selectTakeTheTestTitle,
  selectSlug,
  setCookieBannerMessage,
  setTakeTheTest,
  setSlug,
} from "../../features/contentsSlice";
import { useSelector } from "react-redux";
import { Login, Ingredients } from "../../Constants";
import { getProfileActions } from "../../app/api/useDataManage/userActions";
import { useDispatch } from "react-redux";
import { useGetContentsByTagWebQuery } from "../../features/contentsApiSlice";
import { selectCurrentLanguage } from "../../features/langSlice";
import { Icon } from "../../Components/elements/icon";
import DashboardMenu from "../../Components/Customer/components/DashboardMenu";
import CustomLink from "../../Components/CustomLink";
import { useMetaApiMutation } from "../../features/metaApiSlice";
import { getCookie, getFBP, hashEmail } from "../../utils";
import { v4 as uuidv4 } from "uuid";

const Header = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [freeShippingOrders, setFreeShippingOrders] = useState("");
  const takeTheTestTitle = useSelector(selectTakeTheTestTitle);
  const slug = useSelector(selectSlug);

  const [lang, setLang] = useState();
  const [skip] = useState(false);

  const [token] = useState(localStorage.getItem("customer_token"));
  const quiz_id = localStorage.getItem("quiz_id");
  const userData = useSelector((state) => state.user);

  const {
    data: fetchContents,
    isSuccess,
    refetch,
  } = useGetContentsByTagWebQuery({ tag: "home", lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      if (fetchContents.data.length > 0) {
        // this message used in header
        setFreeShippingOrders(fetchContents.data[22].title);

        dispatch(
          setCookieBannerMessage({ message: fetchContents.data[23].contents })
        );
        dispatch(setTakeTheTest({ title: fetchContents.data[24].title }));
        dispatch(setSlug({ slug: fetchContents.data[24].slug }));
      }
    }
  }, [fetchContents]);

  useEffect(() => {
    if (token && token !== "") {
      dispatch(getProfileActions(token));
    }
  }, [token, dispatch, userData.isAuthenticated]);

  const toggleMenu = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const [metaApi] = useMetaApiMutation();
  const metaEvent = async (event_name) => {
    try {
      const email = localStorage.getItem("email");
      const external_id = sessionStorage.getItem("externalId");
      const fbp = getFBP();
      const fbc = getCookie("_fbc");
      const id = uuidv4();
      if (window.fbq) {
        window.fbq(
          "track",
          event_name,
          {},
          {
            eventID: id,
            user_data: {
              external_id,
              ...(email ? { em: await hashEmail(email) } : {}),
            },
          }
        );
      }
      metaApi({
        event_name,
        page: window.location.href,
        email,
        id,
        external_id,
        fbp,
        fbc,
      })
        .then((res) => {})
        .catch((error) => console.log("Error => ", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <header className={`sticky-top ${isMenuOpen ? " sidebar-active" : ""}`}>
        <nav className="navbar navbar-expand-xl navbar-light">
          <div className="container">
            <div className="d-flex justify-content-between w-100 align-items-center d-xl-none">
              <CustomLink to={"/"}>
                <img src={logo} alt="logo riise" width={80} />
              </CustomLink>

              <div className="d-flex">
                <div className="mobile:!t-hidden">
                  <CustomLink
                    to={slug}
                    onClick={() => metaEvent("Lead")}
                    className="sm:!t-hidden "
                  >
                    <span className="btn btn-custom !t-rounded-lg">
                      {takeTheTestTitle}
                    </span>
                  </CustomLink>
                </div>
                <div className="d-sm-flex d-none">
                  {quiz_id && !token && (
                    <div className="t-relative">
                      <CustomLink className="cart" to="/product-suggestion">
                        <img
                          src={cart}
                          alt=""
                          className="img-fluid"
                          width={33}
                        />
                      </CustomLink>
                      <div className="!t-h-3 !t-w-3 !t-rounded-full t-bg-red-500 t-absolute -t-right-1 t-top-0" />
                    </div>
                  )}

                  {userData.isAuthenticated === true && token !== "" ? (
                    <CustomLink className="login" to={"/dashboard"}>
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span>{t(userData?.user?.name)}</span>
                    </CustomLink>
                  ) : (
                    <CustomLink className="login" to={"/login"}>
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span>{t(Login)}</span>
                    </CustomLink>
                  )}
                </div>
                <Icon
                  name="menu"
                  color="#fff"
                  onClick={(e) => toggleMenu(e)}
                  className="lg:t-hidden navbar-toggler border-0 ms-3"
                />
              </div>
            </div>
            <div
              className="collapse navbar-collapse d-xl-flex d-none"
              id="collapsibleNavId"
            >
              <ul className="navbar-nav mt-2 mt-lg-0 me-1 col-xl-4">
                <li className="nav-item text-center">
                  <CustomLink to={slug} onClick={() => metaEvent("Lead")}>
                    <span className="btn btn-custom !t-rounded-lg">
                      {takeTheTestTitle}
                    </span>
                  </CustomLink>
                </li>
                <li className="nav-item text-center">
                  <CustomLink
                    to="/ingredients"
                    onClick={() => metaEvent("ViewContent")}
                  >
                    <span className="btn me-xl-3 mb-3 mb-xl-0 text-white ">
                      {t(Ingredients)}
                    </span>
                  </CustomLink>
                </li>
                {/* <li className="nav-item text-center">
                  <button
                    className="btn me-xl-3 mb-3 mb-xl-0 text-white "
                    style={{ textTransform: "none", border: "none" }}
                    onClick={navigateToIngredients}
                  >
                    {t(Ingredients)}
                  </button>
                </li> */}
              </ul>
              <ul className="navbar-nav mx-auto mt-2 mt-lg-0 d-xl-flex d-none">
                <li className="nav-item">
                  <CustomLink to={`/`}>
                    <img src={logo} alt="" className="img-fluid" width={80} />
                  </CustomLink>
                </li>
              </ul>
              <ul className="navbar-nav mt-2 mt-lg-0 d-xl-flex d-none align-items-center col-xl-4 justify-content-end">
                {quiz_id && !token && (
                  <li className="!t-relative t-border-r-[1px] t-border-primary t-pr-4">
                    <CustomLink className="cart" to="/product-suggestion">
                      <img src={cart} alt="" className="img-fluid" width={33} />
                    </CustomLink>
                    <div className="!t-h-3 !t-w-3 !t-rounded-full t-bg-red-500 t-absolute t-right-3 t-top-0" />
                  </li>
                )}

                <li className="nav-item t-pl-6">
                  {userData.isAuthenticated === true && token !== "" ? (
                    <CustomLink
                      className="t-flex t-items-center t-gap-1"
                      to={"/dashboard"}
                    >
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span className="t-text-primary">
                        {t(userData?.user?.name)}
                      </span>
                    </CustomLink>
                  ) : (
                    <CustomLink
                      className="t-flex t-items-center t-gap-1"
                      to={`/login`}
                    >
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span className="t-text-primary">{t(Login)}</span>
                    </CustomLink>
                  )}
                </li>
                <Language />
              </ul>
            </div>
          </div>
        </nav>
        <div className="mobile-overlay" />
        <div className="mobile-sidebar t-flex t-flex-col t-py-8 md:t-py-16">
          <Icon
            name="cross"
            color="#fff"
            size="16"
            className="t-absolute t-top-2 t-right-2 t-flex t-items-center t-justify-center t-p-1 t-bg-secondary t-rounded-full"
            onClick={(e) => toggleMenu(e)}
          />
          <CustomLink to="/">
            <img src={logo_home} alt="" className="t-mx-auto" />
          </CustomLink>

          <DashboardMenu setIsMenuOpen={setIsMenuOpen} />
          <div className="!t-absolute t-bottom-4 t-px-4">
            <Language isSidebar />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
