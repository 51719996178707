import { apiSlice } from "../app/api/apiSlice";

export const ContentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContents: builder.query({
      query: (arg) => {
        const { id, lang } = arg;
        return { url: `contentpagewebbyid?id=${id}&lang=${lang}` };
      },
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getContentsByTagWeb: builder.query({
      query: (arg) => {
        const { tag, lang } = arg;
        return { url: `contentpage/listbytagweb?tags=${tag}&lang=${lang}` };
      },
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getFaqs: builder.query({
      query: ({ lang }) => {
        return { url: `faqs/web?lang=${lang}` };
      },
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getSettings: builder.query({
      query: (payload) => ({
        url: "settings",
        method: "POST",
        body: { ...payload },
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getTestimonials: builder.query({
      query: (lang) => {
        return { url: `testimonial/listweb?lang=${lang}` };
      },
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
  }),
});

export const {
  useGetContentsQuery,
  useGetContentsByTagWebQuery,
  useGetFaqsQuery,
  useGetSettingsQuery,
  useGetTestimonialsQuery,
} = ContentsApiSlice;
