import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { logo } from "../Data/Images/Home";
import { useChangeUserPasswordMutation } from "../features/auth/authApiSlice";
import { useGetContentsQuery } from "../features/contentsApiSlice";
import "../assets/scss/pages/_login.css";
import { Buffer } from "buffer";
import CustomLink from "../Components/CustomLink";

const ResetPassword = () => {
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");

  const history = useNavigate();
  const idImage = Buffer.from("58").toString("base64");
  const [changeUserPassword, { isLoading }] = useChangeUserPasswordMutation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [headerImage, setHeaderImage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [confirmErrMsg, setConfirmErrMsg] = useState("");

  const resetpassword = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setConfirmErrMsg("");

    if (password === "") {
      setErrMsg("Password is required");
    } else if (confirmPassword === "") {
      setConfirmErrMsg("Confirm Password is required");
    } else if (password !== confirmPassword) {
      setConfirmErrMsg("Password and confirm password do not match");
    } else {
      try {
        const data = await changeUserPassword({
          token,
          userId,
          password,
        }).unwrap();
        toast.success(data?.message || "Password changed successfully!");
        setTimeout(() => {
          history("/login");
        }, [5000]);
      } catch (err) {
        toast.error(err?.data?.message || "Something went wrong");
      }
    }
  };

  const { data: fetchHeaderImage, isSuccess: isSuccessImage } =
    useGetContentsQuery({ id: idImage, lang: "en", skip: false });

  useEffect(() => {
    if (isSuccessImage) {
      if (fetchHeaderImage.data.picture)
        setHeaderImage(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchHeaderImage.data.picture}`
        );
    }
  }, [fetchHeaderImage, isSuccessImage]);

  return (
    <>
      <Container
        fluid={true}
        className="login-section section-padding pt-0"
        style={{
          backgroundImage: `linear-gradient(var(--secondary-80),var(--secondary-80)) , url(${headerImage})`,
        }}
      >
        <div className="container d-flex t-flex-col align-items-center justify-content-center vh-100 t-gap-20 md:-t-mt-10">
          <div className="mlogo t-mb-4">
            <CustomLink to="/">
              <img src={logo} alt="" width={120} />
            </CustomLink>
          </div>
          <div className="t-w-full t-max-w-[400px] t-mx-auto t-bg-gray-50 t-flex t-flex-col t-gap-16 t-shadow-lg t-rounded-md !t-py-12 t-px-4">
            <div className="t-text-2xl t-font-bold t-text-center t-text-tertiary">
              Reset your password
            </div>
            <form action="" className="t-flex t-flex-col t-gap-8">
              <div className="row textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="password"
                    className="form-control "
                    placeholder="Password"
                    aria-describedby="helpId"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <i className="fa fas fa-key !t-text-lg !t-mt-3" />
                </div>
                {errMsg && (
                  <p className="t-text-left t-text-red-500 !t-mt-1">{errMsg}</p>
                )}
              </div>
              <div className="row textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="password"
                    className="form-control "
                    placeholder="Confirm Password"
                    aria-describedby="helpId"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                  <i className="fa fas fa-key !t-text-lg !t-mt-3" />
                </div>
                {confirmErrMsg && (
                  <p className="t-text-left t-text-red-500 !t-mt-1">
                    {confirmErrMsg}
                  </p>
                )}
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    className="col-12 btn btn-custom-secondary fw-bold lts1 px-5"
                    onClick={(e) => resetpassword(e)}
                    disabled={isLoading}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
