import { apiSlice } from "../app/api/apiSlice";

export const MetaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    metaApi: builder.mutation({
      query: (payload) => ({
        url: "meta-capi",
        method: "POST",
        body: { ...payload },
      }),
    }),
    getOrderId: builder.query({
      query: (id) => ({ url: `meta-order-id?quizId=${id}` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
  }),
});

export const {
  useMetaApiMutation,
  useGetOrderIdQuery,
  useLazyGetOrderIdQuery,
} = MetaApiSlice;
