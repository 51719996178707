import React, { useEffect, useState } from "react";
import BannerImage from "../../../assets/partner/PartnerBanner.webp";
import CustomLink from "../../CustomLink";
import {
  BannerHeading1,
  BannerHeading2,
  BannerText1,
  BannerText2,
  Energy,
  Focus,
  You,
  SportPerformance,
  Mood,
  Sleep,
  Stress,
  Digestion,
  ImmuneSystem,
  Skin,
  Hair,
  Nails,
  MusclePain,
  Eyes,
  BonesAndJoints,
  Heart,
} from "../../../Constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../features/langSlice";

const Banner = () => {
  const { t } = useTranslation();
  const selectedLang = useSelector(selectCurrentLanguage);

  const [focusAreass, setFocusAreass] = useState([]);
  const [currentFocus, setCurrentFocus] = useState(null); // Initialize as null
  const [fadeClass, setFadeClass] = useState("fade-in");

  useEffect(() => {
    const updatedFocusAreass = [
      t(You),
      t(Energy),
      t(Focus),
      t(SportPerformance),
      t(Mood),
      t(Sleep),
      t(Stress),
      t(Digestion),
      t(ImmuneSystem),
      t(Skin),
      t(Hair),
      t(Nails),
      t(Heart),
      t(MusclePain),
      t(Eyes),
      t(BonesAndJoints),
    ];

    setFocusAreass(updatedFocusAreass);
    setCurrentFocus(updatedFocusAreass[0]);
  }, [t, selectedLang]);

  useEffect(() => {
    if (focusAreass.length === 0) return;

    let index = 0;
    const interval = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(() => {
        index = (index + 1) % focusAreass.length;
        setCurrentFocus(focusAreass[index]);
        setFadeClass("fade-in");
      }, 500);
    }, 2000);

    return () => clearInterval(interval);
  }, [focusAreass]);

  return (
    <div className="t-w-full t-max-w-[1330px] t-mx-auto t-flex t-flex-col t-items-center md:t-flex-row ">
      <div className="t-h-full t-flex t-flex-col t-gap-2 md:t-gap-4 t-p-2 md:t-p-8">
        <div className="t-flex t-flex-col t-items-center sm:t-items-start md:t-mb-8">
          <h1 className="t-text-center !t-m-0 t-text-secondary t-text-[22px] md:t-text-[30px]">
            {t(BannerHeading1)}
          </h1>
          <h1 className="t-text-center !t-m-0 t-text-secondary t-text-[22px] md:t-text-[30px]">
            {t(BannerHeading2)}
          </h1>
          <h1
            className={`t-text-center !t-font-roboto t-text-[28px] md:t-text-[36px] t-font-[900] !t-m-0 t-text-secondary `}
            style={{ fontFamily: "Roboto" }}
          >
            {" "}
            <span className={fadeClass}>{currentFocus}</span>
          </h1>
        </div>
        <div className="t-flex t-flex-col t-items-center md:t-items-start t-justify-center md:t-justify-start t-gap-2 md:t-gap-4">
          <p className="!t-m-0 t-text-secondary t-text-sm md:t-text-base t-text-center  md:t-text-start t-w-[80%] md:t-w-[65%]">
            {t(BannerText1)}
          </p>
          <p className="!t-m-0 t-text-secondary t-text-sm md:t-text-base t-text-center md:t-text-start md:t-w-[65%]">
            {t(BannerText2)}
          </p>
          <CustomLink to="/quiz?partner_id=gettestedio">
            <span className="btn btn-custom-tertiary !t-rounded-lg">
              Take the test
            </span>
          </CustomLink>
        </div>
      </div>
      <img
        src={BannerImage}
        alt=""
        className="md:t-mt-16 t-w-full md:t-w-1/3"
      />
    </div>
  );
};

export default Banner;
