import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetLastQuizMutation } from "../../features/customerApiSlice";
import { selectCurrentLanguage } from "../../features/langSlice";
import { useTranslation } from "react-i18next";
import {
  IncludesIngredients,
  Nutrient,
  PersonalizedFormula,
  Quantity,
  RetakeTest,
  YourFocusAreas,
} from "../../Constants";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "./components/DashboardWrapper";
import { useGetAssetsQuery } from "../../features/quizApiSlice";
import FocusAreaCard from "./components/FocusAreaCard";
import { Accordion } from "../elements/Accordion";

function Retake() {
  const navigate = useNavigate();
  const selectedLang = useSelector(selectCurrentLanguage);
  const { t } = useTranslation();
  const [lang, setLang] = useState();
  const [getLastQuiz] = useGetLastQuizMutation();

  const [ingredients, setIngredients] = useState([]);
  const [focusArea, setFocusArea] = useState([]);

  useEffect(() => {
    const lng = localStorage.getItem("lang");

    setLang(lng === "us" ? "en" : lng);
  }, []);

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    const fn = async () => {
      if (lang) {
        try {
          const reqData = { lang };

          const res = await getLastQuiz(reqData).unwrap();

          setIngredients(res.ingredients);
          setFocusArea(res.focusArea);
        } catch (err) {
          console.log("Error -> ", err);
        }
      }
    };

    fn();
  }, [selectedLang, lang, getLastQuiz]);

  const { data: focusAreaImages } = useGetAssetsQuery("focusArea");
  const { data: focusAreaIcons } = useGetAssetsQuery("focusAreaIcon");

  return (
    <DashboardWrapper title={"Retake"}>
      <div className="col-xl-8 col-lg-8 user-inner">
        <div className="profile-reviews-wrapper">
          <button
            onClick={() =>
              navigate("/quiz", {
                state: {
                  quizType: "retake",
                },
              })
            }
            className="btn btn-custom-secondary fw-bold px-5"
          >
            {t(RetakeTest)}
          </button>
        </div>
        <div className="product-suggestion-section section-padding row">
          <div className="col-12 t-flex t-flex-col t-gap-8">
            <div className="t-flex t-flex-col t-gap-2">
              <h3 className="t-m-0">{t(YourFocusAreas)}</h3>
              <div className="t-flex t-flex-wrap t-gap-2">
                {focusArea &&
                  focusArea.map((item) => {
                    const focusImage = focusAreaImages?.data?.find(
                      (i) => i.name === item.answer
                    );
                    const focusIcon = focusAreaIcons?.data?.find(
                      (i) => i.name === item.answer
                    );
                    return (
                      <FocusAreaCard
                        icon={focusIcon?.url}
                        bg={focusImage?.url}
                        name={item.answer}
                        key={"focus-area-dashboard" + item.answer}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="t-flex t-flex-col t-gap-2">
              <h3>{t(PersonalizedFormula)}</h3>
              <h6>
                {t(IncludesIngredients).replace("{0}", ingredients.length)}
              </h6>
              <div className="table-responsive">
                <table className="table accordion" id="accordionExample">
                  <thead>
                    <tr>
                      <td className="t-text-sm md:t-text-base t-font-bold t-w-[55%] 2xl:t-w-[75%]">
                        {t(Nutrient)}
                      </td>
                      <td className="t-text-sm md:t-text-base t-font-bold">
                        {t(Quantity)}
                      </td>
                      <td className="t-text-sm md:t-text-base t-font-bold">
                        % NRV
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {ingredients &&
                      ingredients.map((item, i) => (
                        <tr key={"ingredient-" + i}>
                          <td>
                            <Accordion heading={item.ingredient}>
                              <p className="t-text-sm">{item.description}</p>
                            </Accordion>
                          </td>
                          <td className="t-text-xs md:t-text-sm ">
                            {item.daily_dose} mg
                          </td>
                          <td className="t-text-xs md:t-text-sm ">
                            {item.per_eu_nrv === 0
                              ? "*"
                              : `${item.per_eu_nrv}%`}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Retake;
