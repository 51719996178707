import Home from "../Components/Home";
import Faqs from "../Components/faq";
import TermsConditions from "../Components/TermsConditions";
import AboutUs from "../Components/AboutUs";
import Impressum from "../Components/Impressum";
import Policy from "../Components/Policy";
import ContactUs from "../Components/ContactUs";
import Blogs from "../Components/blogs";

import BlogDetails from "../Components/blogs/blogDetails";
import Ingredients from "../Components/ingredients";
import IngredientsDetails from "../Components/ingredients/IngredientDetails";
import Partner from "../Components/Partner";

import Checkout from "../Components/Checkout";

import Quiz from "../Components/Quiz";

import Dashboard from "../Components/Customer/Dashboard";
import Customer from "../Components/Customer/Customer";
import ProductSuggestion from "../Components/Customer/ProductSuggestion";
import { Route } from "react-router-dom";
import React from "react";
import CustomFormula from "../Components/Customer/CustomFormula";
import CustomFormulaSuccess from "../Components/Customer/CustomFormulaSuccess";
import CustomQuizSuccess from "../Components/Quiz/CustomQuizSuccess";
import NotFound from "../Components/NotFound";
import Energy from "../Components/FocusAreas/Energy";
import Focus from "../Components/FocusAreas/Focus";
import Sleep from "../Components/FocusAreas/Sleep";
import SportsPerformance from "../Components/FocusAreas/SportsPerformance";
import Stress from "../Components/FocusAreas/Stress";
import Mood from "../Components/FocusAreas/Mood";
import ImmuneSystem from "../Components/FocusAreas/ImmuneSystem";
import Refill from "../Components/Refill";
import MysteryCredit from "../Components/MysteryCredit";

// export const routes = [
//     { path: `${process.env.PUBLIC_URL}/`, Component: <Home /> },
//     { path: `${process.env.PUBLIC_URL}/faqs`, Component: <Faqs /> },
//     { path: `${process.env.PUBLIC_URL}/legal`, Component: <TermsConditions /> },
//     { path: `${process.env.PUBLIC_URL}/about`, Component: <AboutUs /> },
//     { path: `${process.env.PUBLIC_URL}/impressum`, Component: <Impressum /> },
//     { path: `${process.env.PUBLIC_URL}/policy`, Component: <Policy /> },
//     { path: `${process.env.PUBLIC_URL}/contactus`, Component: <ContactUs /> },
//     { path: `${process.env.PUBLIC_URL}/blogs`, Component: <Blogs /> },
//     { path: `${process.env.PUBLIC_URL}/blogs/:slug`, Component: <BlogDetails /> },
//     { path: `${process.env.PUBLIC_URL}/checkout`, Component: <Checkout /> },
//
// ]

export const routes = [
  { path: `/faqs`, Component: <Faqs /> },
  { path: `/legal`, Component: <TermsConditions /> },
  { path: `/about`, Component: <AboutUs /> },
  { path: `/impressum`, Component: <Impressum /> },
  { path: `/policy`, Component: <Policy /> },
  { path: `/contactus`, Component: <ContactUs /> },
  { path: `/blogs`, Component: <Blogs /> },
  { path: `/blogs/:slug`, Component: <BlogDetails /> },

  { path: `/ingredients`, Component: <Ingredients /> },
  { path: `/ingredients/:slug`, Component: <IngredientsDetails /> },

  { path: `/checkout`, Component: <Checkout /> },
  { path: `/partner`, Component: <Partner /> },
  { path: `/for-your-energy`, Component: <Energy /> },
  { path: `/for-your-focus`, Component: <Focus /> },
  { path: `/for-your-sleep`, Component: <Sleep /> },
  { path: `/for-your-stress`, Component: <Stress /> },
  { path: `/for-your-mood`, Component: <Mood /> },
  { path: `/for-your-immune-system`, Component: <ImmuneSystem /> },
  { path: `/for-your-sport-performance`, Component: <SportsPerformance /> },
  { path: `/refill`, Component: <Refill /> },
  { path: `/mystery-credit`, Component: <MysteryCredit /> },
  { path: `/*`, Component: <NotFound /> },
];

// export const quizRoutes = [
//     { path: `${process.env.PUBLIC_URL}/quiz`, Component: <Quiz /> },
// ];

export const quizRoutes = [
  { path: `/quiz`, Component: <Quiz /> },
  { path: `/custom-quiz`, Component: <Quiz /> },
  { path: `/custom-quiz-success`, Component: <CustomQuizSuccess /> },
  { path: `/product-suggestion`, Component: <ProductSuggestion /> },
  { path: `/custom-formula`, Component: <CustomFormula /> },
  { path: `/custom-formula/success`, Component: <CustomFormulaSuccess /> },
];

// export const customerRoutes = [
//     { path: `${process.env.PUBLIC_URL}/customer`, Component: <Customer /> },
//     { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
// ];

export const customerRoutes = [
  { path: `/customer`, Component: <Customer /> },
  { path: `/dashboard`, Component: <Dashboard /> },
];
