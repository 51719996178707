import React, { useMemo, useState } from "react";
import TableRow from "./TableRow";
import { Icon } from "../../elements/icon";
import { useTranslation } from "react-i18next";
import { Nutrient, Quantity } from "../../../Constants";

const IngredientsAccordion = ({
  data,
  allIngredients,
  allIngredientCodes,
  onRemoveQuantity,
  onAddQuantity,
  prices,
  removeIngredientFromBin,
  onInputChange,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const updatedData = useMemo(
    () => (open ? data : data.slice(0, 2)),
    [open, data]
  );
  return (
    <div className="t-flex t-flex-col t-gap1">
      <div className="table-responsive !t-relative -t-mt-2">
        <table className="table accordion" id="accordionExample">
          <thead>
            <tr>
              <td className="t-text-[11px] md:t-text-sm t-w-[35%] md:t-w-[40%] t-font-bold">
                {t(Nutrient)}
              </td>
              <td className="t-text-[11px] md:t-text-sm t-text-center t-font-bold">
                {t(Quantity)}
              </td>
              <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                %NRV
              </td>
              <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                €
              </td>
              <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold"></td>
            </tr>
          </thead>
          <tbody>
            {updatedData &&
              updatedData.map((item, i) => {
                const ingredient = allIngredients?.data?.find(
                  (ing) =>
                    ing.ingredient === item?.ingredient ||
                    ing.ingredient_de === item?.ingredient
                );
                const ingredientData = allIngredientCodes?.data?.find(
                  (ing) => ing?.code === ingredient?.id
                );
                const euNrv = parseFloat(ingredientData?.eu_nrv);
                return (
                  <TableRow
                    key={"ingredient-" + i}
                    item={item}
                    i={i}
                    onRemoveQuantity={onRemoveQuantity}
                    ingredientData={ingredientData}
                    onAddQuantity={onAddQuantity}
                    prices={prices}
                    removeIngredientFromBin={removeIngredientFromBin}
                    euNrv={euNrv}
                    onInputChange={onInputChange}
                  />
                );
              })}
          </tbody>
        </table>
        <div
          className={`${
            !open &&
            "t-absolute t-bottom-5 t-bg-white t-h-10 t-w-full t-drop-shadow-[0_-12px_12px_rgba(255,255,255,0.7)]"
          }  t-flex t-items-center t-justify-center `}
        >
          {data.length > 2 && (
            <div
              className="t-w-full  t-flex t-items-center t-justify-center t-gap-1 t-cursor-pointer"
              onClick={() => setOpen(!open)}
            >
              <Icon
                name={open ? "chevronUp" : "chevronDown"}
                color="gray"
                size="16"
                className="t-mt-1"
              />
              <p className="t-m-0  t-text-center">
                {!open && data.length}{" "}
                {open ? "Hide ingredients" : "ingredients"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IngredientsAccordion;
