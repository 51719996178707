import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Image1 from "../../../assets/carousel/1.webp";
import Image2 from "../../../assets/carousel/2.webp";
import Image3 from "../../../assets/carousel/3.webp";
import Image4 from "../../../assets/carousel/4.webp";
import Image5 from "../../../assets/carousel/5.webp";
import Image1g from "../../../assets/carousel/1g.webp";
import Image2g from "../../../assets/carousel/2g.webp";
import Image3g from "../../../assets/carousel/3g.webp";
import Image4g from "../../../assets/carousel/4g.webp";
import Image5g from "../../../assets/carousel/5g.webp";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../features/langSlice";
import { Icon } from "../../elements/icon";
const images = [
  { en: Image1, de: Image1g },
  { en: Image2, de: Image2g },
  { en: Image3, de: Image3g },
  { en: Image4, de: Image4g },
  { en: Image5, de: Image5g },
];

const CarouselProduct = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div className="t-relative">
      <Carousel
        selectedItem={currentSlide}
        onChange={(index) => setCurrentSlide(index)}
        showArrows={true}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) => (
          <Icon
            name="chevronLeft"
            onClick={hasPrev ? onClickHandler : undefined}
            className={`t-absolute -t-left-1 t-top-1/2 t-rounded-[0_8px_8px_0] t-p-1 t-w-8 t-h-8 t-z-10
              ${
                hasPrev
                  ? "t-bg-white t-text-black t-shadow-md hover:t-bg-gray-200"
                  : "t-bg-gray-300 t-text-gray-500 t-cursor-not-allowed t-opacity-50"
              }`}
          />
        )}
        renderArrowNext={(onClickHandler, hasNext, label) => (
          <Icon
            name="chevronRight"
            onClick={hasNext ? onClickHandler : undefined}
            className={`t-absolute -t-right-1 t-top-1/2 t-rounded-[8px_0_0_8px] t-p-1 t-w-8 t-h-8 t-z-10
              ${
                hasNext
                  ? "t-bg-white t-text-black t-shadow-md hover:t-bg-gray-200"
                  : "t-bg-gray-300 t-text-gray-500 t-cursor-not-allowed t-opacity-50"
              }`}
          />
        )}
      >
        {images.map((img, index) => (
          <div key={index}>
            <img src={selectedLang === "de" ? img.de : img.en} alt="" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselProduct;
