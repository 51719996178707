import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useMetaApiMutation } from "../features/metaApiSlice";
import { v4 as uuidv4 } from "uuid";
import { getCookie, getFBP, hashEmail } from "../utils";

const LanguageEnforcer = ({ children }) => {
  const location = useLocation();

  const [metaApi] = useMetaApiMutation();
  const metaEvent = async () => {
    try {
      const id = uuidv4();
      const external_id = sessionStorage.getItem("externalId");
      const fbp = getFBP();
      const fbc = getCookie("_fbc");
      const email = localStorage.getItem("email");
      if (window.fbq) {
        window.fbq(
          "track",
          "PageView",
          {},
          {
            eventID: id,
            user_data: {
              external_id,
              ...(email ? { em: await hashEmail(email) } : {}),
            },
          }
        );
      }
      metaApi({
        event_name: "PageView",
        page: window.location.href,
        id,
        email,
        external_id,
        fbp,
        fbc,
      })
        .then((res) => {})
        .catch((error) => console.log("Error => ", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    metaEvent();
  }, [location.pathname]);

  return children;
};

export default LanguageEnforcer;
