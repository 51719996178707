import React, { useEffect } from "react";
import { logo } from "../../Data/Images/Home";
import Language from "../../Layout/Header/Language";
import {
  useLazyGetOrderIdQuery,
  useMetaApiMutation,
} from "../../features/metaApiSlice";
import CustomLink from "../CustomLink";
import { v4 as uuidv4 } from "uuid";
import { getCookie, getFBP, hashEmail } from "../../utils";
const OrderComplete = () => {
  const quiz_id = localStorage.getItem("quiz_id");

  const [getOrderId] = useLazyGetOrderIdQuery(quiz_id);
  const [metaApi] = useMetaApiMutation();

  useEffect(() => {
    if (quiz_id) {
      getOrderId(quiz_id)
        .then(async (res) => {
          if (res?.data?.data) {
            const id = uuidv4();
            const email = localStorage.getItem("email");
            const external_id = sessionStorage.getItem("externalId");
            const fbp = getFBP();
            const fbc = getCookie("_fbc");
            if (window.fbq) {
              window.fbq(
                "track",
                "Purchase",
                {
                  currency: "EUR",
                  value: res?.data?.data?.totalPrice,
                },
                {
                  eventID: id,
                  user_data: {
                    external_id,
                    ...(email ? { em: await hashEmail(email) } : {}),
                  },
                }
              );
            }
            metaApi({
              event_name: "Purchase",
              id,
              currency: "EUR",
              value: res?.data?.data?.totalPrice,
              email,
              page: window.location.href,
              external_id,
              fbp,
              fbc,
            })
              .then((res) => {})
              .catch((error) => console.log("Error => ", error));
          }
        })
        .catch((error) => {
          console.log("Error => ", error);
        });
    }
  }, [quiz_id]);

  return (
    <>
      <div className="t-sticky t-top-0 t-z-10 t-w-full t-bg-secondary t-flex t-items-center t-justify-center t-py-2">
        <div className="container t-flex t-items-center t-justify-center">
          <img src={logo} alt="" width={60} />
          <div className="t-ml-auto">
            <Language />
          </div>
        </div>
      </div>
      <div className="t-w-full t-h-[80vh] product-suggestion-section section-padding t-flex t-flex-col t-items-center t-justify-center t-gap-4">
        <h2 className="text-center">Order successfully completed</h2>

        <h4 className="text-center">
          Check your email for completing your profile
        </h4>
        <CustomLink
          to={"/"}
          className="!t-text-sm !t-text-capitalize btn-custom-quaternary !t-px-8 t-py-2 !t-bg-secondary disabled:!t-bg-quaternary hover:!t-text-white"
        >
          <h6 className="text-center t-m-0">Go to home</h6>
        </CustomLink>
      </div>
    </>
  );
};

export default OrderComplete;
