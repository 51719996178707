import "./assets/css/bootstrap5.1.3.min.css";
//import './assets/fonts/fonts2.css';
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/custom.css";
import "./assets/css/checkout.css";
import "./assets/css/product-suggestion.css";

import { Provider } from "react-redux";
import { store } from "./app/store";
import Routers from "./Route";
import { v4 as uuidv4 } from "uuid";
import RouteChangeTracker from "./Route/RouteChangeTracker";
import CookieBanner from "./Components/CookieBanner";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    sessionStorage.setItem("externalId", uuidv4());
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <RouteChangeTracker />
        <Routers />
        <CookieBanner />
      </Provider>
    </div>
  );
}

export default App;
