import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import { useAnswersMutation } from "../../features/quizApiSlice";
import { Link, useLocation } from "react-router-dom";
import {
  getProductForWebAction,
  getSubsActions,
} from "../../app/api/useDataManage/subscriptionApis";
import { getCustomerProfileAction } from "../../app/api/useDataManage/userActions";
import { useTranslation } from "react-i18next";
import {
  AsSeenIn,
  YourFocusAreas,
  YourFocusAreasDesc,
  PersonalizedFormulaDesc,
  IncludesIngredients,
  Nutrient,
  Quantity,
  SubscriptionOptions,
  SubscriptionDetailsLine,
  ResetButton,
  AddButton,
  SelectAPlanMobile,
  StartNowButton,
  SecurePayment,
  ShippedIn2To3Days,
  Verified,
  CustomerReviews,
  FormulatedByExperts,
  Available,
  AvailableText,
  FormulaHeading,
  HereMainHeading,
  ProductInfo1,
  ProductInfo2,
  ProductInfo3,
  ProductInfo4,
  ProductInfo5,
  FreeShippingBanner,
  DiscountBannerText,
  Off25,
  Vegan,
  GlutenFree,
  NoArtificialCloning,
  SuitableForAthletes,
  Histamin,
} from "../../Constants";

import { logo } from "../../Data/Images/Home";
import ProductSuggestionWhyRiise from "./ProductSuggestionWhyRiise";
import Language from "../../Layout/Header/Language";
import SubscriptionCard from "../SubscriptionCard";
import FocusAreaCard from "./components/FocusAreaCard";
import { calculateIngredientPrice } from "../../utils/productSuggestions";
import MobileSubscriptionCard from "../MobileSubscriptionCard";
import AddIngredientModal from "./components/AddIngredientModal";
import TableRow from "./components/TableRow";
import Reviews from "./components/Reviews";
import ExpertSection from "./components/ExpertSection";
import useProduct from "../../hooks/useProduct";
import ReviewsMobile from "./components/ReviewsMobile";
import Whatsapp from "../../assets/whatsapp.webp";
import PaymentMethods from "../../assets/payment-methods.webp";
import Shipping from "../../assets/shipping-DHL.webp";
import ReviewsImage from "../../assets/reviews.webp";
import ReviewsOverlay from "../Reviews";
import InfoCard from "./components/InfoCard";
import PersonalFormulasSection from "../Home/PersonalFormulasSection";
import HereForYou from "./components/HereForYou";
import Carousel from "./components/Carousel";
import FocusAreaAccordion from "./components/FocusAreaAccordion";
import Loader from "../elements/Loader";
import CountdownTimer from "../CountdownTimer";
import { useMetaApiMutation } from "../../features/metaApiSlice";
import CustomLink from "../CustomLink";
import MakeChoice from "../Home/MakeChoice";
import HowToEnjoy from "./components/HowToEnjoy";
import VeganImg from "../../assets/vegan.webp";
import GlutenFreeImg from "../../assets/gluten-free.webp";
import NoArtificialColoringImg from "../../assets/no-additives.webp";
import SuitableForAthletesimg from "../../assets/suitable-for-athletes-doping-free.webp";
import Tickimg from "../../assets/tick.webp";
import { v4 as uuidv4 } from "uuid";
import { getCookie, getFBP, hashEmail } from "../../utils";
import IngredientsAccordion from "./components/IngredientsAccordion";

const ProductSuggestion = () => {
  let { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    onInputChange,
    removeIngredientFromBin,
    addIngredient,
    removeIngredient,
    onSubmitData,
    onRemoveQuantity,
    onAddQuantity,
    onSelectPlan,
    focusAreaImages,
    focusAreaIcons,
    allIngredients,
    allIngredientCodes,
    allIngredientPrices,
    allIngredientPriceLoading,
    isProductPricingLoading,
    onSelectOneOff,
    news,
    lang,
    setLang,
    data,
    setData,
    focusArea,
    setFocusArea,
    ingredients,
    setIngredients,
    quizId,
    setQuizId,
    user,
    setUser,
    quantitiesChanged,
    setQuantitiesChanged,
    error,
    setError,
    prices,
    setPrices,
    pricesData,
    setPricesData,
    totals,
    setTotals,
    continent,
    setContinent,
    paymentLink,
    setPaymentLink,
    subscribtion,
    setSubscription,
    token,
    addIngredientsModal,
    setAddIngredientsModal,
    newSubs,
    setNewSubs,
    ONE_OFF_SUB,
    isFocusAreaImagesLoading,
    isFocusAreaIconsLoading,
    isAllIngredientsLoading,
    isAllIngredientCodesLoading,
    isNewsLoading,
  } = useProduct();

  const selectedLang = useSelector(selectCurrentLanguage);
  const { subs } = useSelector((state) => state.subs);
  // const [isButtonVisible, setIsButtonVisible] = useState(true);
  const buttonRef = useRef(null);
  const boxRef = useRef(null);
  const subsRef = useRef();
  const mobileSubsRef = useRef();

  // All queries and mutations
  const [answers, { isLoading }] = useAnswersMutation();

  const loading =
    allIngredientPriceLoading ||
    isProductPricingLoading ||
    isLoading ||
    isFocusAreaImagesLoading ||
    isFocusAreaIconsLoading ||
    isAllIngredientsLoading ||
    isAllIngredientCodesLoading ||
    isNewsLoading;

  // ALl USE EFFECTS HERE
  useEffect(() => {
    if (subs) {
      const tempSubs = subs.slice(1, subs.length);
      const sub = [ONE_OFF_SUB, ...tempSubs];
      setNewSubs(sub);
    }
  }, [subs, setNewSubs, ONE_OFF_SUB]);

  useEffect(() => {
    const receivedData = state;
    if (receivedData && receivedData.quiz_id > 0) {
      setQuizId(receivedData.quiz_id);
      localStorage.setItem("quiz_id", receivedData.quiz_id);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getSubsActions(lang));
    dispatch(getProductForWebAction());
    dispatch(getCustomerProfileAction(token));
  }, [dispatch]);

  // This will set the links to NO_EU if the country is in the list
  useEffect(() => {
    if (state) {
      let country = "";
      if (state?.slideValues[0]?.country?.value) {
        country = state?.slideValues[0]?.country?.value;
      } else if (state?.slideValues[8]?.country?.value) {
        country = state?.slideValues[8]?.country?.value;
      }
      if (
        country &&
        [
          "England",
          "Scotland",
          "Wales",
          "Northern Ireland",
          "Norway",
          "Switzerland",
        ].includes(country)
      ) {
        setContinent("non-eu");
      }
    }
  }, [state]);

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);
    setQuizId(localStorage.getItem("quiz_id"));
    dispatch(getSubsActions(lang));

    if (quizId > 0 && lang) {
      answers({ quiz_id: quizId, answers: null, lang: lang }) //.unwrap();
        .then((res) => {
          const { ingredients, focusArea, user } = res.data;
          setData(ingredients);
          setIngredients(ingredients);
          setFocusArea(focusArea);
          setUser(user?.user);
          // setLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });

      state = null;
    }
  }, [lang, selectedLang, quizId]);

  useEffect(() => {
    if (state && lang && state.quiz_id === undefined) {
      answers({ quiz_id: 0, answers: state, lang: lang }) //.unwrap();
        .then((res) => {
          const { quiz_id, ingredients, focusArea } = res.data;

          setQuizId(quiz_id);
          setData(ingredients);
          setIngredients(ingredients);
          setFocusArea(focusArea);
          localStorage.setItem("quiz_id", quiz_id);
          // setLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });

      state = null;
    }
  }, [state, lang]);

  // THis will calculate the initial prices for the ingredients
  useEffect(() => {
    if (!ingredients.length || !allIngredientPrices?.data?.length) return;
    setPrices({});
    ingredients.forEach((item) => {
      if (parseFloat(item.daily_dose) > 0) {
        calculateIngredientPrice(
          item,
          parseFloat(item.daily_dose),
          setPrices,
          setPaymentLink,
          setSubscription,
          allIngredientPrices,
          false
        );
        calculateIngredientPrice(
          item,
          parseFloat(item.daily_dose),
          setPricesData,
          setPaymentLink,
          setSubscription,
          allIngredientPrices,
          false
        );
      }
    });
  }, [ingredients, allIngredientPrices]);

  // THis will calculate the price for the product
  useEffect(() => {
    const totalSum = Object.values(prices).reduce(
      (sum, value) => sum + parseFloat(value),
      0
    );
    const shipping = totalSum >= 55 ? 0 : continent === "eu" ? 6 : 12;

    setTotals({
      oneMonth: totalSum + shipping,
      threeMonths: totalSum * 0.9, // 10% off of 1 month price + shipping
      sixMonths: totalSum * 0.7, // 30% off of 1 month price, free shipping
    });
  }, [prices]);

  // For hide/show mobile overlay next 2 useEffects
  useEffect(() => {
    const handleScrollToSection = () => {
      const scrollTo = subsRef?.current?.offsetTop;
      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    };

    const button = buttonRef.current;
    button.addEventListener("click", handleScrollToSection);

    return () => {
      button.removeEventListener("click", handleScrollToSection);
    };
  }, []);

  const [reviewData, setReviewData] = useState();
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const reviewsCount = document.querySelectorAll(
        ".cssVar-subheading__number"
      );
      if (reviewsCount) {
        const reviews = [];
        reviewsCount.forEach((element) => {
          reviews.push(element.innerHTML);
        });
        setReviewData(reviews);
      }
    });

    // Start observing the target node for DOM changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const sectionRef = useRef(null);
  const sectionRefMobile = useRef(null);

  const scrollToSectionEnd = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  const scrollToSectionEndMobile = () => {
    if (sectionRefMobile.current) {
      sectionRefMobile.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const ingredientsRef = useRef(null);
  const ingredientsBelowRef = useRef(null);

  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    if (!mobileSubsRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBannerVisible(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    observer.observe(mobileSubsRef.current);

    return () => {
      if (mobileSubsRef.current) observer.unobserve(mobileSubsRef.current);
    };
  }, []);

  useEffect(() => {
    if (newSubs.length === 0) return;
    const oneMonthSub = newSubs.find((sub) => sub?.duration === "3 months");
    if (oneMonthSub) {
      onSelectPlan(oneMonthSub);
    }
  }, [newSubs, totals]);

  const [metaApi] = useMetaApiMutation();
  const metaEvent = async () => {
    try {
      const email = localStorage.getItem("email");
      const external_id = sessionStorage.getItem("externalId");
      const fbp = getFBP();
      const fbc = getCookie("_fbc");
      const id = uuidv4();
      if (window.fbq) {
        window.fbq(
          "track",
          "Contact",
          {},
          {
            eventID: id,
            user_data: {
              external_id,
              ...(email ? { em: await hashEmail(email) } : {}),
            },
          }
        );
      }
      metaApi({
        event_name: "Contact",
        page: window.location.href,
        email,
        id,
        external_id,
        fbp,
        fbc,
      })
        .then((res) => {})
        .catch((error) => console.log("Error => ", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  const ICONS_DATA = [
    { title: t(Vegan), img: VeganImg },
    { title: t(GlutenFree), img: GlutenFreeImg },
    { title: t(NoArtificialCloning), img: NoArtificialColoringImg },
    { title: t(SuitableForAthletes), img: SuitableForAthletesimg },
    { title: t(Histamin), img: Tickimg },
  ];

  return (
    <>
      <div className="t-sticky t-top-0 t-z-10 t-w-full t-bg-secondary t-flex t-items-center t-justify-center t-py-2">
        <div className="container t-flex t-items-center t-justify-center">
          <CustomLink to="/">
            <img src={logo} alt="" width={60} />
          </CustomLink>
          <div className="t-ml-auto">
            <Language />
          </div>
        </div>
      </div>
      <div
        className="t-flex t-items-center t-justify-center t-text-center !t-text-xs sm:!t-text-base md:!t-text-lg t-py-1 t-bg-black t-text-white t-font-bold"
        style={{ marginBottom: "0px" }}
      >
        <p className="t-m-0">{t(DiscountBannerText)}&nbsp; </p>
        <p className="t-m-0 t-italic t-underline">{t(Off25)}&nbsp;&nbsp;</p>
        <p className="t-m-0 t-underline">{t(FreeShippingBanner)}</p>
      </div>
      <div className="sm:t-hidden">
        <Carousel />
      </div>
      <div className="t-flex t-items-start t-py-1 t-px-6 t-mt-2">
        <ReviewsOverlay
          totalReviews={reviewData?.[1]?.trim() ?? 0}
          averageReviews={reviewData?.[0]?.trim() ?? 0}
          onClick={scrollToSectionEndMobile}
          className="md:!t-hidden"
        />
      </div>
      <div
        className="product-suggestion-section section-padding t-flex t-flex-col md:t-flex-row t-items-center t-gap-8"
        style={{ paddingTop: "10px" }}
      >
        <div className="t-relative container t-max-w-[1320px] t-mx-auto t-flex t-flex-col md:t-flex-row t-gap-8 md:t-gap-12">
          <div className="lg:t-w-[40%] t-flex t-flex-col t-gap-4 ">
            <div className="t-flex t-flex-col t-gap-0">
              <div className="t-flex t-items-center t-gap-8">
                <h2 className="!t-pb-0">
                  {t(YourFocusAreas)}
                  {user?.name.trim()}
                </h2>
              </div>
              <p>{t(YourFocusAreasDesc)}</p>
              <div className="t-hidden sm:t-flex t-flex-wrap t-gap-2">
                {focusArea &&
                  focusArea.map((item) => {
                    const focusImage = focusAreaImages?.data?.find(
                      (i) => i?.name === item?.answer
                    );
                    const focusIcon = focusAreaIcons?.data?.find(
                      (i) => i?.name === item?.answer
                    );
                    return (
                      <FocusAreaCard
                        icon={focusIcon?.url}
                        bg={focusImage?.url}
                        name={item.answer}
                        key={"focus-area-" + item.answer}
                      />
                    );
                  })}
              </div>
              <div className="sm:t-hidden">
                <FocusAreaAccordion
                  focusArea={focusArea}
                  focusAreaIcons={focusAreaIcons}
                  focusAreaImages={focusAreaImages}
                />
              </div>
              <div className="t-mt-2 sm:t-hidden">
                <p className="t-m-0">{t(ProductInfo1)}</p>
                <ul>
                  <li>
                    {data.length} {t(ProductInfo2)}
                  </li>
                  <li>{t(ProductInfo3)}</li>
                  <li>{t(ProductInfo4)}</li>
                </ul>
                <p className="t-m-0">{t(ProductInfo5)}</p>
              </div>
            </div>
            <div className="t-hidden sm:t-flex t-flex-col t-gap-0">
              <p className="!t-mb-0">{t(PersonalizedFormulaDesc)}</p>
            </div>
            <ReviewsOverlay
              totalReviews={reviewData?.[1]?.trim() ?? 0}
              averageReviews={reviewData?.[0]?.trim() ?? 0}
              onClick={scrollToSectionEnd}
              className="!t-hidden md:!t-flex"
            />
            <div className="t-w-full t-flex t-items-start t-justify-between sm:t-gap-4 t-mx-auto">
              {ICONS_DATA.map((item) => (
                <div
                  className="t-flex-none t-w-1/6 t-flex t-flex-col t-items-center t-justify-center t-gap-1"
                  key={"unique-" + item.title}
                >
                  <img src={item.img} alt="" height={40} width={40} />
                  <p className="t-m-0 t-text-xs md:t-text-sm t-text-center t-text-secondary t-leading-4">
                    {item.title}
                  </p>
                </div>
              ))}
            </div>

            <div className="t-flex t-items-center t-justify-between t-gap-4">
              <h5 className="t-text-lg md:t-text-2xl">
                {t(IncludesIngredients).replace("{0}", data.length)}
              </h5>
              <div className="t-flex i-items-center t-gap-4">
                <button
                  className="t-group btn !t-w-fit !t-px-3 text-capitalize btn-custom-quaternary !t-rounded-md !t-py-1"
                  onClick={() => {
                    setQuantitiesChanged(false);
                    setData(ingredients);
                    setError({});
                    setPrices(pricesData);
                  }}
                  disabled={!quantitiesChanged}
                >
                  {t(ResetButton)}
                </button>
                <button
                  className="btn !t-w-fit text-capitalize btn-custom-quaternary !t-rounded-md !t-py-1 !t-px-3"
                  onClick={() => setAddIngredientsModal(true)}
                >
                  {t(AddButton)}
                </button>
              </div>
            </div>

            <div
              ref={ingredientsRef}
              className="table-responsive t-hidden md:t-block -t-mt-2"
            >
              <table className="table accordion" id="accordionExample">
                <thead>
                  <tr>
                    <td className="t-text-[11px] md:t-text-sm t-w-[35%] md:t-w-[40%] t-font-bold">
                      {t(Nutrient)}
                    </td>
                    <td className="t-text-[11px] md:t-text-sm t-text-center t-font-bold">
                      {t(Quantity)}
                    </td>
                    <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                      %NRV
                    </td>
                    <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                      €
                    </td>
                    <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold"></td>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, i) => {
                      const ingredient = allIngredients?.data?.find(
                        (ing) =>
                          ing.ingredient === item?.ingredient ||
                          ing.ingredient_de === item?.ingredient
                      );
                      const ingredientData = allIngredientCodes?.data?.find(
                        (ing) => ing?.code === ingredient?.id
                      );
                      const euNrv = parseFloat(ingredientData?.eu_nrv);
                      return (
                        <TableRow
                          key={"ingredient-" + i}
                          item={item}
                          i={i}
                          onRemoveQuantity={onRemoveQuantity}
                          ingredientData={ingredientData}
                          onAddQuantity={onAddQuantity}
                          prices={prices}
                          removeIngredientFromBin={removeIngredientFromBin}
                          euNrv={euNrv}
                          onInputChange={onInputChange}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="md:t-hidden">
              <IngredientsAccordion
                data={data}
                allIngredients={allIngredients}
                allIngredientCodes={allIngredientCodes}
                onRemoveQuantity={onRemoveQuantity}
                onAddQuantity={onAddQuantity}
                prices={prices}
                removeIngredientFromBin={removeIngredientFromBin}
                onInputChange={onInputChange}
              />
            </div>

            <section className="section-padding !t-pt-0 !t-pb-0 md:t-min-h-[80px]">
              <h2 className="t-text-center t-font-bold t-mb-0 t-text-tertiary">
                {t(AsSeenIn)}
              </h2>
              <div className="container t-flex t-flex-row t-items-center t-justify-center t-gap-2 md:t-gap-4 lg:t-gap-8">
                {news?.data?.map((item) => (
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}${item?.url}`}
                    alt=""
                    className="t-w-16 t-h-auto md:t-w-[15%]"
                    key={"news-img-" + item.url}
                  />
                ))}
              </div>
            </section>

            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-my-4 md:t-min-h-[288px]">
              <ProductSuggestionWhyRiise />
            </div>
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-my-8">
              <HowToEnjoy />
            </div>
            <div
              className="t-hidden md:t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4 md:t-min-h-[495px]"
              ref={sectionRef}
            >
              <h2>{t(CustomerReviews)}</h2>
              <Reviews />
            </div>
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4 md:t-min-h-[406px]">
              <h2>{t(FormulatedByExperts)}</h2>
              <ExpertSection lang={lang} />
            </div>
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 md:t-min-h-[464px]">
              <MakeChoice isProductPage />
            </div>
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 md:t-min-h-[464px]">
              <h2 className="t-text-left">{t(FormulaHeading)}</h2>
              <PersonalFormulasSection isProductPage />
            </div>
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-mt-4 md:t-min-h-[274px]">
              <h2 className="t-text-left">{t(HereMainHeading)}</h2>
              <HereForYou isProductPage />
            </div>
          </div>
          <div
            ref={subsRef}
            className="t-flex t-flex-col t-space-x-1 t-space-y-2 !md:t-sticky !md:t-top-24 !t-h-fit sticky-top-products !t-pb-[100px]"
          >
            <h2 className="mb-0">{t(SubscriptionOptions)}</h2>
            <div className="t-flex t-flex-col">
              <p className="t-text-sm t-mt-0 t-mb-1">
                {t(SubscriptionDetailsLine)}
              </p>
              <div className="t-flex t-items-start t-gap-1 t-flex-row">
                <div className="t-flex-none t-w-3 t-h-3 t-bg-green-400 t-mt-[5px] t-rounded-full" />
                <p className="t-text-sm t-m-0 t-text-green-400 !t-font-bold">
                  {t(Available)}&nbsp;
                  <span className="t-text-sm t-mt-0 t-text-black">
                    - {t(AvailableText)}
                  </span>
                </p>
                {/* <p className="t-text-sm t-mt-0 t-mb-1"></p> */}
              </div>
              {/* <div className="t-flex t-items-center t-justify-center md:t-justify-start t-mt-1">
                <CountdownTimer />
              </div> */}
            </div>
            <div className="t-hidden sm:t-flex t-flex-wrap t-justify-center t-items-stretch t-gap-4 t-pb-32 md:t-pb-0">
              {newSubs?.slice(0, 3).map((item, key) => (
                <SubscriptionCard
                  item={item}
                  onSelectPlan={onSelectPlan}
                  subscribtion={subscribtion}
                  id={key}
                  totals={totals}
                  key={"subscription-" + key}
                  loading={isProductPricingLoading || allIngredientPriceLoading}
                  onSelectOneOff={onSelectOneOff}
                  paymentLink={paymentLink}
                />
              ))}
            </div>
            <div ref={mobileSubsRef}>
              <MobileSubscriptionCard
                username={user?.name.trim()}
                subscribtion={subscribtion}
                subs={newSubs}
                onSelectPlan={onSelectPlan}
                totals={totals}
                paymentLink={paymentLink}
                onSubmitData={onSubmitData}
                onSelectOneOff={onSelectOneOff}
                error={error}
                boxRef={boxRef}
              />
            </div>
            <div className="t-hidden md:t-grid gap-3 mt-2">
              {paymentLink ? (
                <button
                  className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary !t-rounded-lg"
                  onClick={() => onSubmitData(paymentLink)}
                  disabled={Object.values(error).some(
                    (value) => value === true
                  )}
                >
                  <div>{t(StartNowButton)}</div>
                </button>
              ) : (
                <div className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary-disable !t-rounded-lg">
                  <div>{t(StartNowButton)}</div>
                </div>
              )}
            </div>
            <div className="t-flex t-items-start t-justify-center t-gap-8">
              <InfoCard
                image={PaymentMethods}
                desc={t(SecurePayment)}
                className="md:!t-w-1/2"
              />
              <InfoCard
                image={Shipping}
                desc={t(ShippedIn2To3Days)}
                pClass="md:t-mt-[10px]"
              />
              <InfoCard
                image={ReviewsImage}
                desc={t(Verified)}
                pClass="md:t-mt-[10px]"
              />
            </div>
            <div ref={ingredientsBelowRef}>
              <div className="md:t-hidden t-flex t-flex-col t-gap-4 !t-my-8">
                <ProductSuggestionWhyRiise />
              </div>
              <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-overflow-auto t-my-8">
                <HowToEnjoy />
              </div>
              <div
                className="md:t-hidden t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4"
                ref={sectionRefMobile}
              >
                <h2>{t(CustomerReviews)}</h2>
                <ReviewsMobile />
              </div>
              <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
                <h2>{t(FormulatedByExperts)}</h2>
                <ExpertSection lang={lang} />
              </div>
              <div className="md:t-hidden t-flex t-flex-col t-gap-4">
                <MakeChoice isProductPage />
              </div>
              <div className="md:t-hidden t-flex t-flex-col t-gap-4">
                <h2 className="t-text-left">{t(FormulaHeading)}</h2>
                <PersonalFormulasSection isProductPage />
              </div>
              <div className="md:t-hidden t-flex t-flex-col t-gap-4 !t-mt-8">
                <h2 className="t-text-left">{t(HereMainHeading)}</h2>
                <HereForYou isProductPage />
              </div>
            </div>
          </div>
        </div>
        <div
          className="t-flex t-flex-col t-items-center t-justify-center t-gap-2 md:t-hidden t-fixed t-bottom-0 t-bg-white t-z-40 t-w-full t-mx-auto t-py-4 "
          style={{ boxShadow: "10px 10px 20px 10px #efefef" }}
          hidden={!isBannerVisible || !data.length}
        >
          {/* {paymentLink ? (
            <div
              ref={buttonRef}
              className="t-flex t-flex-col t-items-center t-justify-center t-gap-2"
            >
              <p className="t-text-xl !t-font-[600] !t-leading-normal !t-m-0">
                {t(SelectAPlanMobile)}:{" "}
                {subscribtion?.title === "6 months"
                  ? Math.round(totals["sixMonths"])
                  : subscribtion?.title === "3 months"
                  ? Math.round(totals["threeMonths"])
                  : Math.round(totals["oneMonth"])}
                €
              </p>
              <button
                className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary !t-rounded-lg"
                // href={`${paymentLink}?prefilled_email=${state?.user?.user?.email}&client_reference_id=${state?.user?.user?.id}`}
                onClick={() => onSubmitData(paymentLink)}
                disabled={Object.values(error).some((value) => value === true)}
              >
                <div>{t(StartNowButton)}</div>
              </button>
            </div>
          ) : ( */}
          <div ref={buttonRef}>
            <p className="t-text-xl !t-font-[600] !t-leading-normal !t-m-0 t-text-center">
              Up to {Math.round(totals["oneMonth"])}€
            </p>
            <button
              className="btn !t-w-fit text-capitalize btn-custom-quaternary !t-rounded-lg"
              onClick={() => {
                const elementTop =
                  mobileSubsRef?.current?.getBoundingClientRect().top +
                  window.scrollY;
                const offset = window.innerHeight / 4;
                const scrollTo = elementTop - offset;

                window.scrollTo({
                  top: scrollTo,
                  behavior: "smooth",
                });
              }}
            >
              {t(SelectAPlanMobile)}
            </button>
          </div>
          {/* )} */}
        </div>
        <a
          href="https://wa.me/+4915156047816"
          target="_blank"
          rel="noreferrer"
          className="t-fixed t-bottom-32 sm:t-bottom-5 t-right-5 t-z-50"
          onClick={metaEvent}
        >
          <img
            src={Whatsapp}
            alt=""
            className="t-h-10 t-w-10 md:t-h-15 md:t-w-15"
          />
        </a>
      </div>
      <AddIngredientModal
        open={addIngredientsModal}
        handleClose={() => setAddIngredientsModal(false)}
        data={data}
        removeIngredient={removeIngredient}
        addIngredient={addIngredient}
        allIngredients={allIngredients}
      />
      <div className="t-pb-32 md:t-pb-16"></div>
      {loading && <Loader />}
    </>
  );
};

export default ProductSuggestion;
