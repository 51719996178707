import React from "react";
import { Helmet } from "react-helmet";

const MetaPixel = () => {
  const META_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
  const externalId = sessionStorage.getItem("externalId");

  return (
    <Helmet>
      <script id="facebook-pixel-script" type="text/javascript">
        {`
          !function(f,b,e,v,n,t,s) {
            if(f.fbq)return;
            n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];
            t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s);
          }(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${META_PIXEL_ID}',{
            external_id: '${externalId}'
          });
          fbq.disablePushState = true
          fbq.allowDuplicatePageViews = true
        `}
      </script>
      <noscript>
        {`
          <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=${META_PIXEL_ID}&ev=PageView&noscript=1" />
        `}
      </noscript>
    </Helmet>
  );
};

export default MetaPixel;
